import React, { HTMLProps } from 'react';
import styles from 'shared/components/tableHeader/tableHeader.module.scss';
import Button from 'shared/components/andtComponents/Button';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import classNames from 'classnames';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper.jsx';
import { Action, CategoryAction, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';

export interface TableLikeHeaderProps extends HTMLProps<HTMLDivElement> {
  isExpanded: boolean;
  title?: string;
  createText?: string;
  onExpandClick(): void;
  totalNumber: number;
  onCreate?(event: React.MouseEvent<HTMLButtonElement>): void;
  permissionsCategory: HierarchicalEntityCategory;
  permissionsAction?: Action | CategoryAction;
}

export const TableLikeHeader: React.FC<TableLikeHeaderProps> = ({
  totalNumber,
  onExpandClick,
  isExpanded,
  children,
  title = '',
  onCreate,
  className,
  createText = `Create ${title}`,
  permissionsCategory,
  permissionsAction = CategoryAction.Create,
  ...divProps
}) => (
  <div {...divProps} className={classNames(styles.header, className)}>
    <div className={styles.title}>
      <Button
        automationid="expand-rows"
        text=""
        isSecondary
        icon={() => <GenerateIcon iconName={isExpanded ? ICONS.expand.name : ICONS.collapse.name} />}
        onClick={onExpandClick}
      />
      <div>
        <span className={styles.titleNumber}>{totalNumber}</span>
        <span>{title}</span>
      </div>
      <div className={classNames(styles.filterWrapper, 'align-items-center')}>{children}</div>
    </div>
    <div className={styles.rightMenu}>
      {onCreate && (
        <ReadOnlyDisplayWrapper isHide={false} category={permissionsCategory} action={permissionsAction}>
          <Button
            text={createText}
            onClick={onCreate}
            icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
            automationid="create-alert-rule"
          />
        </ReadOnlyDisplayWrapper>
      )}
    </div>
  </div>
);

export default TableLikeHeader;
