import React from 'react';
import styles from './K8sOnboarding.module.scss';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import CopyCommandLine from './CopyCommandLine.tsx';

const commandLineText =
  'helm upgrade <PROMETHEUS RELEASE> <OTHER OPTIONAL ARGS> --set kube-state-metrics.extraArgs[0]=--metric-labels-allowlist=pods=[*],nodes=[*]';

const ValidationStep: React.FC = () => {
  return (
    <div className={styles.validationStepContainer}>
      <div className={styles.warning}>
        <div className={styles.notice}>
          <GenerateIcon iconName={ICONS.circleInfo.name} /> Notice:
        </div>
        <div>
          <div>
            <span>To ensure full visibility,</span>{' '}
            <b>Anodot requires node labels to be collected by kube-state-metrics in Prometheus.</b>
          </div>
          <b>These labels are not enabled by default.</b>{' '}
          <span>
            If they are already enabled, click &apos;Next&apos;. Otherwise, follow the instructions below to enable them.
          </span>
        </div>
      </div>
      <div className={styles.codeContainer}>
        <p>
          <b>In order to enable node labels collection by Prometheus, add the extra argument</b>{' '}
          <code>--metric-labels-allowlist=pods=[*],nodes=[*]</code>
          <br />
          <b>to</b> <code>kube-state-metrics</code>.
        </p>
        <p>
          <b>If you are using a Helm chart, this line can be added to the </b>
          <code>values.yaml</code><b> file in this hierarchy</b>
        </p>
        <CopyCommandLine
          className={styles.nestedCode}
          text={'kube-state-metrics:\n  extraArgs:\n    - --metric-labels-allowlist=pods=[*],nodes=[*]'}
        />
        <p>
          <b>
            Or can be set directly from the command line as a set argument for a helm upgrade comment (not recommended,
            as this might be overridden by future upgrades):
          </b>
        </p>
        <CopyCommandLine text={commandLineText} />
      </div>
    </div>
  );
};

export default ValidationStep;
