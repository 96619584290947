import React, { useCallback, useContext, useMemo } from 'react';
import Stepper, { Step } from 'shared/components/andtComponents/Stepper/Stepper';
import onboardingStyles from 'shared/components/andtComponents/Onboarding.module.scss';
import k8sStyles from './K8sOnboarding.module.scss';
import { ReactComponent as K8sIcon } from 'shared/img/images/k8s.svg';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { K8S_ONBOARDING_STEPS_CONFIG } from './config.ts';
import classNames from 'classnames';
import OnboardingContextProvider, { OnboardingContext } from './OnBoardingContext.tsx';

const K8sOnboardingWizard: React.FC<{ onClose?(): void }> = ({ onClose }) => {
  const { currentStepIndex, isNextDisabled, onNext, onBack } = useContext(OnboardingContext);

  const onBackClick = useCallback(() => {
    onBack();
    if (!currentStepIndex && onClose) {
      onClose();
    }
  }, [currentStepIndex, onBack, onClose]);

  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex >= 3;

  const onNextClick = useCallback(() => {
    onNext();
    if (isLastStep && onClose) {
      onClose();
    }
  }, [isLastStep, onClose, onNext]);

  const { component: StepPageComponent, title } = K8S_ONBOARDING_STEPS_CONFIG[currentStepIndex];

  const stepperSteps = useMemo(
    () =>
      K8S_ONBOARDING_STEPS_CONFIG.map(
        ({ titleShort }, index) =>
          ({
            id: index,
            title: titleShort,
          } as Step),
      ),
    [],
  );

  return (
    <div>
      <div className={classNames(onboardingStyles.container, k8sStyles.k8sOnboardingContainer)}>
        <Stepper activeStepIndex={currentStepIndex} steps={stepperSteps} />
        <div className={onboardingStyles.pageScreen}>
          <div className={onboardingStyles.header}>
            <div className={onboardingStyles.headerContent}>
              {currentStepIndex + 1}. {title}
            </div>
            <div className={onboardingStyles.headerLogo}>
              <K8sIcon />
            </div>
          </div>
          <div className={onboardingStyles.body}>{StepPageComponent && <StepPageComponent />}</div>
          <div className={onboardingStyles.footer}>
            <div className={onboardingStyles.footerLeft} />
            <div className={onboardingStyles.footerMenu}>
              <Button
                onClick={onBackClick}
                text={isFirstStep ? 'Cancel' : 'Back'}
                isTextButton={!isFirstStep}
                isSecondary={isFirstStep}
                icon={isFirstStep ? undefined : () => <GenerateIcon iconName={ICONS.chevronLeft.name} />}
              />
              <Button
                onClick={onNextClick}
                disabled={isNextDisabled}
                text={isLastStep ? 'Done' : 'Next'}
                iconPlacement="right"
                icon={isLastStep ? undefined : () => <GenerateIcon iconName={ICONS.chevronRight.name} />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WizardContextWrapper = (props) => (
  <OnboardingContextProvider>
    <K8sOnboardingWizard {...props} />
  </OnboardingContextProvider>
);
export default WizardContextWrapper;
