import React, { CSSProperties, PropsWithChildren, useCallback } from "react";
import styles from './K8sOnboarding.module.scss';
import Button from 'shared/components/andtComponents/Button.tsx';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import classNames from 'classnames';

type Props = {
  className?: string;
  text?: string;
  copyLabel?: string;
};
const CopyIconComponent = () => <GenerateIcon iconName={ICONS.copy.name} />;

const CopyCommandLine: React.FC<Props & PropsWithChildren> = ({ className, text = '', copyLabel = 'Copy', children }) => {
  const [isScrollable, setIsScrollable] = React.useState(false);

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(text);
  }, [text]);

  const codeStyle = isScrollable
    ? {
        overflowX: 'auto',
        whiteSpace: 'pre',
      }
    : {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      };

  return (
    <div className={classNames(styles.commandLine, className)}>
      {children || (<code
        onMouseEnter={() => setIsScrollable(true)}
        onMouseLeave={() => setIsScrollable(false)}
        style={codeStyle as CSSProperties}
      >
        {text}
      </code>)}
      <Button onClick={onCopy} icon={CopyIconComponent} isTextButton text={copyLabel} />
    </div>
  );
};

export default CopyCommandLine;
