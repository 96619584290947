import React, { useCallback, useEffect, useState } from 'react';
import secretGifLoader from 'shared/img/images/K8s-Loader-3.gif';
import styles from '../K8sOnboarding.module.scss';
import DownloadFiles from './DownloadFiles.tsx';
import { KeyError, KeySuccess, KeyWasProvided, NewKeyCreated, NoKey } from './KeyFeedbacks.tsx';
import Instructions from 'shared/components/andtComponents/Instructions';
import { FetchLinkResponse, GenerateLinkResponse, useK8sOnboarding } from '../useK8sOnboarding.ts';
import { GenerationResponseStatus } from '../types.ts';
import { InProgressModal, RevokeConfirmationModal } from './RevokeConfirmationModal.tsx';

const YamlStep: React.FC = () => {
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isFirstGeneration, setIsFirstGeneration] = useState(true);
  const { generateLink, fetchOneTimeLink } = useK8sOnboarding();
  const { data: generateLinkData, mutate: handleLinkGeneration, isPending: isLoading } = generateLink();
  const { data: fetchLinkData } = fetchOneTimeLink(generateLinkData?.dagId, {
    enabled: isInProgress,
    refetchInterval: 15 * 1000,
  });

  useEffect(() => {
    handleLinkGeneration(false);
  }, [handleLinkGeneration]);

  useEffect(() => {
    if (generateLinkData?.status === GenerationResponseStatus.IS_IN_PROGRESS) {
      setIsInProgress(true);
    }
    if (generateLinkData?.status) {
      setIsFirstGeneration(false);
    }
  }, [generateLinkData?.status]);

  useEffect(() => {
    /* stop refetching if link was defined */
    setIsInProgress((s) => (s && fetchLinkData?.status !== GenerationResponseStatus.IS_IN_PROGRESS ? false : s));
    if (fetchLinkData?.status !== GenerationResponseStatus.IS_IN_PROGRESS) {
      setShowRevokeModal(false);
    }
  }, [fetchLinkData?.status]);

  const onRevokeLink = useCallback(() => {
    handleLinkGeneration(true);
  }, [handleLinkGeneration]);

  if (!generateLinkData && isFirstGeneration) {
    return (
      <div style={{ height: 400 }} className={'d-flex align-items-center justify-content-center flex-column'}>
        <img src={secretGifLoader} alt="loading" width={167} style={{ maxWidth: 167 }} />
        <div className={'mt-3'}>We are currently generating your Access Keys and YAML files...</div>
      </div>
    );
  }

  return (
    <div className={styles.yamlFilesContainer}>
      <Instructions
        steps={[
          <DownloadFiles key={'download-files'} onDownload={() => null} />,
          getComponentByStatus(setShowRevokeModal, fetchLinkData, generateLinkData),
        ]}
      />
      <RevokeConfirmationModal
        open={showRevokeModal && !isLoading && !isInProgress}
        onClose={() => setShowRevokeModal(false)}
        onConfirm={onRevokeLink}
      />
      <InProgressModal open={isInProgress || (showRevokeModal && isLoading)} />
    </div>
  );
};

export default YamlStep;

function getComponentByStatus(
  setShowRevokeModal: (s: boolean) => void,
  fetchData?: FetchLinkResponse,
  generateData?: GenerateLinkResponse,
) {
  if (fetchData?.link) {
    if (fetchData?.status === GenerationResponseStatus.FIRST_TIME_GENERATED) {
      return <KeySuccess link={fetchData.link} />;
    } else {
      return <NewKeyCreated link={fetchData.link} />;
    }
  }

  switch (generateData?.status) {
    case GenerationResponseStatus.PREVIOUSLY_GENERATED:
      return <KeyWasProvided onGenerateNew={() => setShowRevokeModal(true)} />;
    case GenerationResponseStatus.ERROR:
      return <KeyError />;
    default:
      return <NoKey />;
  }
}
