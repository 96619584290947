import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  PagingPanel,
  TableColumnVisibility,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Action, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { SuccessNotificationsLabels } from 'shared/constants/notificationsConstants';
import toast from 'shared/components/andtComponents/Toast';
import { Col, Row } from 'reactstrap';
import Delete from 'mdi-react/TrashCanOutlineIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import { kFormatter } from 'shared/utils/strUtil';
import SendIcon from 'mdi-react/SendIcon';
import { RdsIsActiveValues } from 'usage/constants/usageConstants';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import TableWrapper from 'shared/components/tables/TableWrapper';
import ReportModal from 'shared/components/reportModal/ReportModal';
import { TEST_MODAL_TYPE } from 'usage/constants/costAndUsageConstants';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { useRootStore } from '~/app/contexts/RootStoreContext.jsx';

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const linkStyle = {
  display: 'inline-block',
  position: 'relative',
  zIndex: 1,
  padding: '1em',
  margin: '-1em',
};

const ReportsTable = ({
  usersStore,
  rows,
  columns,
  columnWidths,
  tableColumnExtensions,
  defaultHiddenColumnNames,
  defaultSorting,
  currenciesColumns,
  deleteHandler,
  updateReportHandler,
  sendReportHandler,
  shareHandler,
  getCurrencyNumber,
}) => {
  const [currentColumnWidths, setCurrentColumnWidths] = useState(columnWidths);
  const [sendReportModalOpenReport, setSendReportModalOpenReport] = useState(false);
  const [currentHiddenColumnNames, setCurrentHiddenColumnNames] = useState(defaultHiddenColumnNames);
  const { appStore } = useRootStore();
  const pageSizes = [5, 10, 20];

  const filteringStateColumnExtensions = [
    { columnName: 'userAction', filteringEnabled: false },
    { columnName: 'snapshot', filteringEnabled: false },
  ];

  const handleOpenSendReportModal = (report) => {
    setSendReportModalOpenReport(report || null);
  };

  const userActionsFormatter = (data) => {
    return (
      <i>
        <Row>
          {appStore.isKeyCloakManagement && (
            <ReadOnlyDisplayWrapper isHide={false} category={HierarchicalEntityCategory.Reports} action={Action.Update}>
              <Col>
                <LinkIconButton containerStyle={buttonContainerStyle}>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      shareHandler(data.row);
                    }}
                  />
                  <GenerateIcon iconName={ICONS.share.name} style={{ width: 20, height: 20 }} />
                </LinkIconButton>
              </Col>
            </ReadOnlyDisplayWrapper>
          )}
          <Col>
            <ReadOnlyDisplayWrapper isHide={false} category={HierarchicalEntityCategory.Reports} action={Action.Update}>
              <LinkIconButton containerStyle={buttonContainerStyle}>
                <a href="/" onClick={(e) => deleteHandler(e, data.row.id)} />
                <Delete />
              </LinkIconButton>
            </ReadOnlyDisplayWrapper>
          </Col>
          <Col>
            <ReadOnlyDisplayWrapper isHide={false} category={HierarchicalEntityCategory.Reports} action={Action.Update}>
              <LinkIconButton containerStyle={buttonContainerStyle}>
                <a href="/" onClick={(e) => updateReportHandler(e, data.row.id)} />
                <PencilIcon />
              </LinkIconButton>
            </ReadOnlyDisplayWrapper>
          </Col>
          <Col>
            <LinkIconButton containerStyle={buttonContainerStyle}>
              <button
                type="button"
                style={{
                  ...linkStyle,
                  color: '#70bbfd',
                }}
                className="btn-no-style"
                onClick={() => {
                  handleOpenSendReportModal(data.row);
                }}
              />
              <SendIcon />
            </LinkIconButton>
          </Col>
        </Row>
      </i>
    );
  };

  const snapShotFormatter = (data) => {
    return !data.row.isSnapShot ? null : (
      <i>
        <Row>
          <Col>
            <CheckIcon color="#B4BCC0" />
          </Col>
        </Row>
      </i>
    );
  };

  const currenciesFormatter = (value) => getCurrencyNumber(kFormatter(value.value));

  const changeColumnWidths = (columnWidths) => {
    setCurrentColumnWidths(columnWidths);
  };

  const hiddenColumnNamesChange = (hiddenColumnNames) => {
    setCurrentHiddenColumnNames(hiddenColumnNames);
  };

  const csvModifiedRows = JSON.parse(JSON.stringify(rows));
  csvModifiedRows.map((row) => {
    if (row.isActive === RdsIsActiveValues.NOT_ACTIVE) {
      row.isActive = 'No';
      return row;
    }

    row.isActive = 'Yes';
    return row;
  });
  return (
    // ref={refCallback}
    <Row style={{ width: '100%' }}>
      <ReportModal
        onClose={() => {
          setSendReportModalOpenReport(null);
        }}
        modalType={TEST_MODAL_TYPE}
        isOpen={!!sendReportModalOpenReport}
        onSave={(data) => {
          sendReportHandler(data);
          toast.success(SuccessNotificationsLabels.REPORT_SENT);
          setSendReportModalOpenReport(null);
        }}
        usersStore={usersStore}
        title="Send report"
        data={sendReportModalOpenReport}
      />
      <Col xs={12} md={12} lg={12} xl={12}>
        <Paper>
          <Grid rows={rows} columns={columns}>
            <FilteringState defaultFilters={[]} columnExtensions={filteringStateColumnExtensions} />
            <IntegratedFiltering />
            <SortingState defaultSorting={defaultSorting} />
            <IntegratedSorting />
            <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[3]} />
            <IntegratedPaging />
            {currenciesColumns && <DataTypeProvider for={currenciesColumns} formatterComponent={currenciesFormatter} />}
            <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatter} />
            <DataTypeProvider for={['snapshot']} formatterComponent={snapShotFormatter} />
            <TableWrapper columnExtensions={tableColumnExtensions} />
            <TableColumnResizing columnWidths={currentColumnWidths} onColumnWidthsChange={changeColumnWidths} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow showFilterSelector />
            <PagingPanel pageSizes={pageSizes} />
            <TableColumnVisibility
              hiddenColumnNames={currentHiddenColumnNames}
              onHiddenColumnNamesChange={hiddenColumnNamesChange}
            />
            <Toolbar />
            <ColumnChooser />
          </Grid>
        </Paper>
      </Col>
    </Row>
  );
};

ReportsTable.propTypes = {
  usersStore: PropTypes.object.isRequired,
  rows: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  columnWidths: PropTypes.array.isRequired,
  tableColumnExtensions: PropTypes.object.isRequired,
  defaultHiddenColumnNames: PropTypes.object.isRequired,
  defaultSorting: PropTypes.object.isRequired,
  currenciesColumns: PropTypes.object.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  shareHandler: PropTypes.func.isRequired,
  updateReportHandler: PropTypes.func.isRequired,
  sendReportHandler: PropTypes.func.isRequired,
  getCurrencyNumber: PropTypes.func.isRequired,
};

const ObserverReportsTable = withUserSettingsConsumer(ReportsTable);
export default ObserverReportsTable;
