import ClusterInformationStep from './ClusterInformationStep';
import ValidationStep from './ValidationStep.tsx';
import YamlStep from './YAMLFilesStep';
import InstallationStep from './InstallationStep.tsx';
import { AddK8Cluster } from './types.ts';

export const K8S_ONBOARDING_STEPS_CONFIG = [
  {
    title: 'Enter Cluster Configuration Details',
    titleShort: 'Cluster Information',
    component: ClusterInformationStep,
  },
  {
    title: 'Configuration validation of existing Prometheus server',
    titleShort: 'Validation',
    component: ValidationStep,
  },
  {
    title: 'Configure YAML File with Access Keys',
    titleShort: 'YAML files',
    component: YamlStep,
  },
  {
    title: 'Installation Commands',
    titleShort: 'Installation Commands',
    component: InstallationStep,
  },
];

export enum AddClusterFields {
  linkedAccountId = 'linkedAccountId',
  clusterName = 'clusterName',
  agentNamespace = 'agentNamespace',
  haveServer_block = 'haveServerBlock',
  prometheusUrl = 'prometheusUrl',
  prometheusConnectingCreds_block = 'prometheusConnectingCredsBlock',
  prometheusConnectingCreds_username = 'prometheusConnectingCredsUsername',
  prometheusConnectingCreds_password = 'prometheusConnectingCredsPassword',
  httpHeaders_block = 'httpHeadersBlock',
  httpHeaders = 'httpHeaders', //'{"X-Scope-OrgID": "YOUR-TENANT-NAME"}'
  kubeStateMetricsJobName_block = 'kubeStateMetricsJobNameBlock', // KUBE_STATE_METRICS_JOB_NAME: 'kube-state-metrics', KUBELET_JOB_NAME: 'kubelet'
  kubeStateMetricsJobName = 'kubeStateMetricsJobName', // KUBE_STATE_METRICS_JOB_NAME: 'kube-state-metrics', KUBELET_JOB_NAME: 'kubelet'
  kubeletJobName_block = 'kubeletJobNameBlock',
  kubeletJobName = 'kubeletJobName', // KUBE_STATE_METRICS_JOB_NAME: 'kube-state-metrics', KUBELET_JOB_NAME: 'kubelet'
  otherMetrics_block = 'otherMetricsBlock',
  otherMetrics_key = 'otherMetricsKey',
  otherMetrics_value = 'otherMetricsValue',
}

export const addClusterDefaultState: AddK8Cluster = {
  id: '',
  [AddClusterFields.linkedAccountId]: '',
  [AddClusterFields.clusterName]: '',
  [AddClusterFields.agentNamespace]: 'anodot-cost',
  [AddClusterFields.haveServer_block]: false,
  [AddClusterFields.prometheusUrl]: 'http://prometheus-kube-prometheus-prometheus:9090/',
  [AddClusterFields.prometheusConnectingCreds_block]: false,
  [AddClusterFields.prometheusConnectingCreds_username]: '',
  [AddClusterFields.prometheusConnectingCreds_password]: '',
  [AddClusterFields.httpHeaders_block]: false,
  [AddClusterFields.httpHeaders]: [],
  [AddClusterFields.kubeletJobName_block]: false,
  [AddClusterFields.kubeStateMetricsJobName_block]: false,
  [AddClusterFields.kubeStateMetricsJobName]: 'kube-state-metrics',
  [AddClusterFields.kubeletJobName]: 'kubelet',
  [AddClusterFields.otherMetrics_block]: false,
  [AddClusterFields.otherMetrics_key]: 'cluster',
  [AddClusterFields.otherMetrics_value]: '',
};

export const addClusterLabels = {
  [AddClusterFields.linkedAccountId]: { label: 'Linked Account ID', error: 'The field is required' },
  [AddClusterFields.clusterName]: { label: 'Cluster Name', error: 'Cluster Name is required' },
  [AddClusterFields.agentNamespace]: { label: 'Agent Namespace', error: 'Agent Namespace is required' },
  [AddClusterFields.prometheusUrl]: { label: 'Prometheus URL', error: 'Prometheus URL is required' },
  [AddClusterFields.httpHeaders]: { error: 'HTTP Headers are required' },
  [AddClusterFields.otherMetrics_value]: { error: 'The field is required' },
  [AddClusterFields.otherMetrics_key]: { error: 'The field is required' },
  [AddClusterFields.kubeStateMetricsJobName]: { error: 'The field is required' },
  [AddClusterFields.kubeletJobName]: { error: 'The field is required' },
  [AddClusterFields.prometheusConnectingCreds_password]: { error: 'The password is required' },
  [AddClusterFields.prometheusConnectingCreds_username]: { error: 'The username is required' },
};
