import React, { useCallback, useEffect, useState } from 'react';
import RowLikeAccordion from 'shared/components/RowLikeAccordion';
import { TableLikeHeader } from 'shared/components/TableLikeHeader.tsx';
import classNames from 'classnames';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { AlertTypeOnUpdate, AlertTypesEnum, CommitmentExpAlert } from '../types.ts';
import styles from '../alerts.module.scss';
import UpdateModal from './UpdateModal.tsx';
import t from '../texts.ts';
import { useAlerts } from '../hooks/useAlerts.ts';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal.tsx';
import AlertForm from './Form.tsx';
import EditDeleteButtons from '../components/EditDeleteButtons.tsx';
import { isDefaultExpanded } from '../ServiceCostTab/constants.ts';
import EmptyAlerts from '../components/EmptyState';
import { Channel, Recipient } from 'shared/components/RecipientsSelect';
import ShareEntityModal from 'shared/components/ShareEntityModal/ShareEntityModal';
import { HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels.js';

export const hideCustomerNameField = true; // TODO: remove after validation
const numberToAlertType = [
  'changeCostService',
  'commitementExpirationRi',
  'commitementExpirationSP',
] as AlertTypeOnUpdate[];

export interface CommitmentExpirationTabProps {
  expirationAlerts: CommitmentExpAlert[];

  refetchAlerts(): void;
}

const CommitmentExpirationTab: React.FC<CommitmentExpirationTabProps> = ({ expirationAlerts, refetchAlerts }) => {
  const { usersStore } = useRootStore();
  const [isAllExpanded, setIsAllExpanded] = useState(isDefaultExpanded);

  const [isSPExpanded, setIsSPExpanded] = useState(isDefaultExpanded);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [activeAlert, setActiveAlert] = useState<CommitmentExpAlert | null>(null);
  const { updateAlerts, deleteAlert, checkSPPlansExist } = useAlerts();
  const { mutate: handleUpdateAlert } = updateAlerts(refetchAlerts);
  const { refetch: handleDelete } = deleteAlert(activeAlert?.uuid ?? '');
  const {
    data: isSpPlansExist = false,
    isLoading: isSpPlansLoading,
    refetch: refetchIsSpPlansExist,
  } = checkSPPlansExist();
  const onCloseDeleteAlert = useCallback(() => setShowDeleteModal(false), []);
  const isAzure = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE;
  const canAddAlertsEmpty = isAzure && isSpPlansExist && !isSpPlansLoading;

  const { getChannels } = useChannels();
  const { data } = getChannels();
  const channels: Channel[] = data as Channel[];

  useEffect(() => {
    if (isAzure) {
      refetchIsSpPlansExist();
    }
  }, [isAzure, refetchIsSpPlansExist]);

  useEffect(() => {
    if (expirationAlerts?.length === 0) {
      setIsSPExpanded(false);
    }
  }, [expirationAlerts]);

  const onUpdate = useCallback(
    (daysBeforeExpiry: number, recipientsEmails: string, recipients: Recipient[], uuid?: string) => {
      handleUpdateAlert([
        {
          uuid,
          daysBeforeExpiry,
          recipientsEmails,
          recipients,
          alertStatus: 'save',
          alertType: numberToAlertType[AlertTypesEnum.SP_EXPIRATION],
          cloudType: CLOUD_TYPE_IDS.GCP,
        },
      ]);
      setShowUpdateModal(false);
    },
    [handleUpdateAlert],
  );

  const onShareClick = (alert: CommitmentExpAlert) => {
    setActiveAlert(alert);
    setShowShareModal(true);
  };

  const onCreateClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveAlert(null);
    setShowUpdateModal(true);
  }, []);

  const onEditClick = useCallback((alert: CommitmentExpAlert) => {
    setActiveAlert(alert);
    setShowUpdateModal(true);
  }, []);

  const onDeleteClick = useCallback((alert: CommitmentExpAlert) => {
    setActiveAlert(alert);
    setShowDeleteModal(true);
  }, []);

  const onDeleteAlert = useCallback(() => {
    handleDelete();
    setTimeout(refetchAlerts, 1000);
    setShowDeleteModal(false);
  }, [handleDelete, refetchAlerts]);

  const onAllExpanded = useCallback(() => {
    setIsAllExpanded(!isAllExpanded);
    setIsSPExpanded(!isAllExpanded);
  }, [isAllExpanded]);

  const showEmptyState = !expirationAlerts.length && isAzure;

  return (
    <div>
      <TableLikeHeader
        onExpandClick={onAllExpanded}
        title={t('ALERT_RULES')}
        createText={t('CREATE_ALERT_RULE')}
        isExpanded={isAllExpanded}
        totalNumber={expirationAlerts.length || 0}
        className={styles.marginBottom32}
        onCreate={onCreateClick}
        permissionsCategory={HierarchicalEntityCategory.Alerts}
      >
        <span className={styles.alertSwitcherLabel}>{`${t('ENABLE_ALERTS')} (${expirationAlerts.length})`}</span>
      </TableLikeHeader>
      {showEmptyState ? (
        <EmptyAlerts canAdd={canAddAlertsEmpty} onAdd={onCreateClick} />
      ) : (
        <RowLikeAccordion
          isExternalExpand={isSPExpanded && expirationAlerts.length > 0}
          isExpandDisabled={!expirationAlerts.length}
          headerContent={t('CUD_EXPIRATION')}
          onExpand={(e, expanded) => setIsSPExpanded(expanded)}
        >
          {expirationAlerts.map((alert) => (
            <div
              key={`${alert.uuid}alert-container`}
              className={styles.alertsRow}
              automation-id={`${alert.uuid}alert-container`}
            >
              <AlertForm
                className={classNames(styles.commitmentForm, styles.formDisabled)}
                daysBeforeExpiry={alert.daysBeforeExpiry}
                recipientsEmails={alert.recipientsEmails}
                channels={channels}
                recipients={alert?.recipients}
              />
              <EditDeleteButtons
                style={{ marginTop: 13 }}
                onEdit={() => onEditClick(alert)}
                onDelete={() => onDeleteClick(alert)}
                onShare={() => onShareClick(alert)}
                alertId={alert.uuid}
              />
            </div>
          ))}
        </RowLikeAccordion>
      )}

      {showUpdateModal && (
        <UpdateModal
          isEdit={Boolean(activeAlert)}
          onUpdate={onUpdate}
          onClose={() => setShowUpdateModal(false)}
          daysBeforeExpiry={activeAlert?.daysBeforeExpiry}
          recipientsEmails={activeAlert?.recipientsEmails}
          recipients={activeAlert?.recipients}
          uuid={activeAlert?.uuid}
          channels={channels}
        />
      )}
      {showShareModal && (
        <ShareEntityModal
          entity={HierarchicalEntityCategory.Alerts}
          entityId={activeAlert!.uuid}
          onClose={() => {
            setShowShareModal(false);
            setActiveAlert(null);
          }}
        />
      )}
      {showDeleteModal && <DeleteConfirmationModal onClose={onCloseDeleteAlert} onDelete={onDeleteAlert} />}
    </div>
  );
};

export default CommitmentExpirationTab;
