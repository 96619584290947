import { HierarchicalEntityCategory, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import { UsersType } from 'users/constants/usersConstants';
import { cleanRoutePath } from '~/shared/utils/routerUtils.js';

export class BaseRoutes {
  static Commitment = '/commitment';
  static CostAndUsage = '/cost-usage';
  static CostAllocation = '/cost-allocation';
  static Kubernetes = '/k8s';
  static Monitoring = '/monitoring';
  static Recommendations = '/recommendations';
  static Services = '/services';
  static Partner = '/partner';
  static CostGpt = '/cost-gpt';
}

export const Routes = {
  //  Admin ****
  ADMIN_ACCOUNTS_STATUS: '/admin-accounts-status',
  //  General ****
  ACCOUNT: '/account',
  ORGANIZATION: '/organization',
  ORGANIZATION_USERS: '/organization/users',
  ORGANIZATION_ROLES: '/organization/roles',
  ORGANIZATION_COST_CENTERS: '/organization/cost-centers',
  ORGANIZATION_CHANNELS: '/organization/channels',
  ORGANIZATION_CLOUD_ACCOUNTS: '/organization/cloud-accounts',
  ORGANIZATION_CUSTOMERS: '/organization/customers',
  ORGANIZATION_LINKED_ACCOUNTS: '/organization/linked-accounts',
  ORGANIZATION_SETTINGS: '/organization/settings',
  FEATURE_FLAGS: '/feature-flags',
  REGISTER: '/register',
  REGISTER_NEW_USER_MANAGEMENT: '/register-new',
  REGISTER_MSP: '/register-msp',
  REGISTER_MSP_NEW_USER_MANAGEMENT: '/register-new-msp',
  REGISTER_SNOW: '/snow-register',
  REGISTER_PROCURE_IT: '/procure-it-register',
  REGISTER_SNOW_MSP: '/snow-register-msp',
  LOG_IN: '/log_in',
  RESET_PASSWORD: '/generate-password/:force?',
  ADMIN_CONTROL_PANEL: '/admin-control-panel',
  DASHBOARD: '/dashboard',
  MAIN_DASHBOARD: '/main-dashboard',
  ACCOUNTS_OVERVIEW: '/accounts-overview',
  ONBOARDING_AWS: '/onboarding/aws',
  ONBOARDING_AZURE: '/onboarding/azure',
  ONBOARDING_GCP: '/onboarding/gcp',
  ONBOARDING_BYOD: '/onboarding/byod',
  WELCOME: '/welcome',
  LANDING: '/landing',
  NOT_ALLOWED: '/notAllowed',
  CUSTOMERS: '/customers',
  AZURE_BILLING_RULES: '/azure-billing-rules',
  SLACK_INTEGRATION: '/slack',
  DESIGN_SYSTEM: '/design-system',
  // Partner ****
  BILLING_RULES_NEW: `${BaseRoutes.Partner}/billing-rules`,
  BILLING_SUMMARY: `${BaseRoutes.Partner}/billing-summary`,
  AWS_BILLING_RULES: `${BaseRoutes.Partner}/aws-billing-rules`,
  AWS_CREDITS_NEW: `${BaseRoutes.Partner}/credits`,
  AWS_CUSTOMERS: `${BaseRoutes.Partner}/aws-customers`,
  CUSTOMERS_HISTORY: `${BaseRoutes.Partner}/customers-history`,
  AZURE_CUSTOMERS_HISTORY: `${BaseRoutes.Partner}/azure-customers-history`,
  CUSTOMERS_MANAGE: `${BaseRoutes.Partner}/customers-manage`,
  CUSTOMERS_REPORTS: `${BaseRoutes.Partner}/customers-reports`,
  AZURE_CUSTOMERS: `${BaseRoutes.Partner}/azure-customers`,
  REBILLING_PREFERENCES: `${BaseRoutes.Partner}/billing/preferences`,
  //  Commitment ****
  COMMITMENT_DASHBOARD: `${BaseRoutes.Commitment}/dashboard`,
  RI_UTILIZATION: `${BaseRoutes.Commitment}/utilization/ri`,
  SAVINGS_PLANS_UTILIZATION: `${BaseRoutes.Commitment}/utilization/sp`,
  SAVINGS_PLANS_ANALYZER: `${BaseRoutes.Commitment}/savings-plans-analyzer`,
  SAVINGS_PLANS_ANALYZER_NEW: `${BaseRoutes.Commitment}/savings-plans-analyzer-new`,
  // Cost & Usage ****
  REPORTS: `${BaseRoutes.CostAndUsage}/reports`,
  COST_USAGE_EXPLORER: `${BaseRoutes.CostAndUsage}/cost-usage-explorer`,
  METRICS_EXPLORER: `${BaseRoutes.CostAndUsage}/metrics-explorer`,
  RESOURCE_EXPLORER: `${BaseRoutes.CostAndUsage}/resource-explorer`,
  CUSTOM_DASHBOARD: `${BaseRoutes.CostAndUsage}/dashboards`,
  CUSTOM_DASHBOARD_INSTANCE: `${BaseRoutes.CostAndUsage}/dashboards/:id`,
  CUSTOM_DASHBOARD_TEMPLATE_INSTANCE: `${BaseRoutes.CostAndUsage}/dashboards-templates/:id`,
  CUSTOM_DASHBOARD_TEMPLATE: `${BaseRoutes.CostAndUsage}/dashboards-templates`,
  CUSTOM_DASHBOARDS_PANELS: `${BaseRoutes.CostAndUsage}/panels`,
  VIRTUAL_TAGS: `${BaseRoutes.CostAllocation}/virtual-tags`,
  FILTER_GROUP: `${BaseRoutes.CostAllocation}/filter-group`,
  BUSINESS_MAPPING: `${BaseRoutes.CostAllocation}/business-mapping`,
  BUSINESS_MAPPING_GLOBAL_GROUPS: `${BaseRoutes.CostAllocation}/business-mapping-global`,
  BUSINESS_MAPPING_GROUPS: `${BaseRoutes.CostAllocation}/business-mapping/:id`,
  ACCOUNT_TAGS: `${BaseRoutes.CostAllocation}/account-tags`,
  VIEWS: `${BaseRoutes.CostAllocation}/views`,
  ASSETS: `${BaseRoutes.CostAndUsage}/assets`,
  //  Monitoring ****
  CUE_ALERTS: `${BaseRoutes.Monitoring}/alerts`,
  ANOMALY_DETECTION: `${BaseRoutes.Monitoring}/anomaly-detection`,
  BUDGET: `${BaseRoutes.Monitoring}/budget`,
  IDLE_INSTANCES: `${BaseRoutes.Monitoring}/idle-instances`,
  // Recommendations
  RECOMMENDATIONS: BaseRoutes.Recommendations,
  RECOMMENDATION_PREFERENCES: `${BaseRoutes.Recommendations}/preferences`,
  RECOMMENDATION_REPORTS: `${BaseRoutes.Recommendations}/reports`,
  RECOMMENDATION_EXPLORER: `${BaseRoutes.Recommendations}/explorer`,
  RECOMMENDATION_360: `${BaseRoutes.Recommendations}/360`,
  // Services ****
  S3: `${BaseRoutes.Services}/s3`,
  EC2: `${BaseRoutes.Services}/ec2`,
  RDS: `${BaseRoutes.Services}/rds`,
  BIG_QUERY: `${BaseRoutes.Services}/big-query`,
  GcpAppEngine: `${BaseRoutes.Services}/app-engine`,
  GcpCloudSQL: `${BaseRoutes.Services}/cloud-sql`,
  GcpComputeEngine: `${BaseRoutes.Services}/compute-engine`,
  AZURE_VIRTUAL_MACHINES: `${BaseRoutes.Services}/azure-virtual-machines`,
  AZURE_STORAGE: `${BaseRoutes.Services}/azure-storage`,
  AZURE_DB_COSTS: `${BaseRoutes.Services}/azure-db-costs`,
  AzureActiveDirectory: `${BaseRoutes.Services}/active-directory`,
  AzureDataFactory: `${BaseRoutes.Services}/data-factory`,
  AZURE_RI_COVERAGE: `${BaseRoutes.Services}/azure-ri-coverage`,
  // Kubernetes ****
  K8S_PREVIEW: `${BaseRoutes.Kubernetes}/preview`,
  K8S_USAGE: `${BaseRoutes.Kubernetes}/usage`,
  K8S_PREFERENCES: `${BaseRoutes.Kubernetes}/preferences`,
  K8S_ASSETS: `${BaseRoutes.Kubernetes}/assets`,
  // CostGPT ****
  COST_GPT: `${BaseRoutes.CostGpt}`,
  PRICING: `http://instances.umbrellacost.io/`,
};

export const mapRoutesAuthorizationCategories = new Map([
  [
    Routes.ADMIN_ACCOUNTS_STATUS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.SystemConfiguration],
    },
  ],
  [
    Routes.ACCOUNT,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [
        HierarchicalEntityCategory.Accounts,
        OrganizationEntityCategory.UsersManagement,
        OrganizationEntityCategory.Roles,
      ],
    },
  ],
  [
    Routes.ORGANIZATION,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [
        HierarchicalEntityCategory.Accounts,
        OrganizationEntityCategory.UsersManagement,
        OrganizationEntityCategory.Roles,
      ],
    },
  ],
  [
    Routes.ORGANIZATION_USERS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.UsersManagement],
    },
  ],
  [
    Routes.ORGANIZATION_ROLES,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.Roles],
    },
  ],
  [
    Routes.ORGANIZATION_COST_CENTERS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.CostCenters],
    },
  ],
  [
    Routes.ORGANIZATION_CUSTOMERS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.ORGANIZATION_CHANNELS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: ['N/A'],
    },
  ],
  [
    Routes.ORGANIZATION_CLOUD_ACCOUNTS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Accounts],
    },
  ],
  [
    Routes.ORGANIZATION_LINKED_ACCOUNTS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Accounts],
    },
  ],
  [
    Routes.ORGANIZATION_SETTINGS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.UsersManagement],
    },
  ],
  [
    Routes.FEATURE_FLAGS,
    {
      notAllowedForUserTypes: [
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.BETA_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.USER_END_TRIAL,
        UsersType.SUB_USER,
        UsersType.RESELLER,
        UsersType.RESELLER_CUSTOMER,
        UsersType.RESELLER_CUSTOMER_EDP,
        UsersType.BUSINESS_ADMIN,
        UsersType.RESELLER_BUSINESS_ADMIN,
      ],
      authorizationCategories: [OrganizationEntityCategory.SystemConfiguration],
    },
  ],
  [Routes.REGISTER, { notAllowedForUserTypes: [UsersType.USER_END_TRIAL], authorizationCategories: ['N/A'] }],
  [
    Routes.REGISTER_NEW_USER_MANAGEMENT,
    { notAllowedForUserTypes: [UsersType.USER_END_TRIAL], authorizationCategories: ['N/A'] },
  ],
  [
    Routes.REGISTER_MSP_NEW_USER_MANAGEMENT,
    { notAllowedForUserTypes: [UsersType.USER_END_TRIAL], authorizationCategories: ['N/A'] },
  ],
  [Routes.REGISTER_MSP, { notAllowedForUserTypes: [UsersType.USER_END_TRIAL], authorizationCategories: ['N/A'] }],
  [Routes.REGISTER_SNOW, { notAllowedForUserTypes: [UsersType.USER_END_TRIAL], authorizationCategories: ['N/A'] }],
  [Routes.REGISTER_SNOW_MSP, { notAllowedForUserTypes: [UsersType.USER_END_TRIAL], authorizationCategories: ['N/A'] }],
  [Routes.LOG_IN, { notAllowedForUserTypes: [UsersType.USER_END_TRIAL], authorizationCategories: ['N/A'] }],
  [Routes.RESET_PASSWORD, { notAllowedForUserTypes: [UsersType.USER_END_TRIAL], authorizationCategories: ['N/A'] }],
  [
    Routes.ADMIN_CONTROL_PANEL,
    {
      notAllowedForUserTypes: [
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.BETA_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.USER_END_TRIAL,
        UsersType.SUB_USER,
        UsersType.RESELLER,
        UsersType.RESELLER_CUSTOMER,
        UsersType.RESELLER_CUSTOMER_EDP,
        UsersType.BUSINESS_ADMIN,
        UsersType.RESELLER_BUSINESS_ADMIN,
      ],
      authorizationCategories: [OrganizationEntityCategory.SystemConfiguration],
    },
  ],
  [
    Routes.DASHBOARD,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.MAIN_DASHBOARD,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.ACCOUNTS_OVERVIEW,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Accounts],
    },
  ],
  [
    Routes.ONBOARDING_AWS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Accounts],
    },
  ],
  [
    Routes.ONBOARDING_AZURE,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Accounts],
    },
  ],
  [
    Routes.ONBOARDING_GCP,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Accounts],
    },
  ],
  [
    Routes.ONBOARDING_BYOD,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Accounts],
    },
  ],
  [
    Routes.WELCOME,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [Routes.LANDING, { notAllowedForUserTypes: [UsersType.USER_END_TRIAL], authorizationCategories: ['N/A'] }],
  [Routes.NOT_ALLOWED, { notAllowedForUserTypes: [UsersType.USER_END_TRIAL], authorizationCategories: ['N/A'] }],
  [
    Routes.CUSTOMERS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.AZURE_BILLING_RULES,
    {
      notAllowedForUserTypes: [
        UsersType.USER_END_TRIAL,
        UsersType.SUPER_USER,
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.SUB_USER,
      ],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [Routes.SLACK_INTEGRATION, { notAllowedForUserTypes: [UsersType.USER_END_TRIAL], authorizationCategories: ['N/A'] }],
  [Routes.DESIGN_SYSTEM, { notAllowedForUserTypes: [UsersType.USER_END_TRIAL], authorizationCategories: ['N/A'] }],
  [
    Routes.AWS_BILLING_RULES,
    {
      notAllowedForUserTypes: [
        UsersType.USER_END_TRIAL,
        UsersType.SUPER_USER,
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.SUB_USER,
      ],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.BILLING_RULES_NEW,
    {
      notAllowedForUserTypes: [
        UsersType.USER_END_TRIAL,
        UsersType.SUPER_USER,
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.SUB_USER,
      ],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.BILLING_SUMMARY,
    {
      notAllowedForUserTypes: [
        UsersType.USER_END_TRIAL,
        UsersType.SUPER_USER,
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.SUB_USER,
      ],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.AWS_CREDITS_NEW,
    {
      notAllowedForUserTypes: [
        UsersType.USER_END_TRIAL,
        UsersType.SUPER_USER,
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.SUB_USER,
      ],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.AWS_CUSTOMERS,
    {
      notAllowedForUserTypes: [
        UsersType.USER_END_TRIAL,
        UsersType.SUPER_USER,
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.SUB_USER,
      ],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.CUSTOMERS_HISTORY,
    {
      notAllowedForUserTypes: [
        UsersType.USER_END_TRIAL,
        UsersType.SUPER_USER,
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.SUB_USER,
      ],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.AZURE_CUSTOMERS_HISTORY,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.CUSTOMERS_MANAGE,
    {
      notAllowedForUserTypes: [
        UsersType.USER_END_TRIAL,
        UsersType.SUPER_USER,
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.SUB_USER,
      ],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.CUSTOMERS_REPORTS,
    {
      notAllowedForUserTypes: [
        UsersType.USER_END_TRIAL,
        UsersType.SUPER_USER,
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.SUB_USER,
      ],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.AZURE_CUSTOMERS,
    {
      notAllowedForUserTypes: [
        UsersType.USER_END_TRIAL,
        UsersType.SUPER_USER,
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.SUB_USER,
      ],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.REBILLING_PREFERENCES,
    {
      notAllowedForUserTypes: [
        UsersType.USER_END_TRIAL,
        UsersType.SUPER_USER,
        UsersType.USER,
        UsersType.NEW_USER,
        UsersType.UN_AUTH_USER,
        UsersType.USER_ON_BOARDED,
        UsersType.SUB_USER,
      ],
      authorizationCategories: [OrganizationEntityCategory.ResellerCustomers],
    },
  ],
  [
    Routes.COMMITMENT_DASHBOARD,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Commitments],
    },
  ],
  [
    Routes.RI_UTILIZATION,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Commitments],
    },
  ],
  [
    Routes.SAVINGS_PLANS_UTILIZATION,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Commitments],
    },
  ],
  [
    Routes.SAVINGS_PLANS_ANALYZER,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Commitments],
    },
  ],
  [
    Routes.SAVINGS_PLANS_ANALYZER_NEW,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Commitments],
    },
  ],
  [
    Routes.REPORTS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Reports],
    },
  ],
  [
    Routes.COST_USAGE_EXPLORER,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.METRICS_EXPLORER,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.RESOURCE_EXPLORER,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.CUSTOM_DASHBOARD,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Dashboards],
    },
  ],
  [
    Routes.CUSTOM_DASHBOARD_INSTANCE,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Dashboards],
    },
  ],
  [
    Routes.CUSTOM_DASHBOARD_TEMPLATE_INSTANCE,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Dashboards],
    },
  ],
  [
    Routes.CUSTOM_DASHBOARD_TEMPLATE,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Dashboards],
    },
  ],
  [
    Routes.CUSTOM_DASHBOARDS_PANELS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Dashboards],
    },
  ],
  [
    Routes.VIRTUAL_TAGS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.CostAllocation],
    },
  ],
  [
    Routes.FILTER_GROUP,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.CostAllocation],
    },
  ],
  [
    Routes.BUSINESS_MAPPING,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.CostAllocation],
    },
  ],
  [
    Routes.BUSINESS_MAPPING_GLOBAL_GROUPS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.CostAllocation],
    },
  ],
  [
    Routes.BUSINESS_MAPPING_GROUPS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.CostAllocation],
    },
  ],
  [
    Routes.ACCOUNT_TAGS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.CostAllocation],
    },
  ],
  [
    Routes.VIEWS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [OrganizationEntityCategory.CostAllocation],
    },
  ],
  [
    Routes.ASSETS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.CUE_ALERTS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Alerts],
    },
  ],
  [
    Routes.ANOMALY_DETECTION,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL, UsersType.USER_ON_BOARDED, UsersType.NEW_USER],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.BUDGET,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL, UsersType.USER_ON_BOARDED, UsersType.NEW_USER],
      authorizationCategories: [HierarchicalEntityCategory.Budgets],
    },
  ],
  [
    Routes.IDLE_INSTANCES,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    `${Routes.RECOMMENDATION_EXPLORER}`,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    `${Routes.RECOMMENDATION_REPORTS}`,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    `${Routes.RECOMMENDATION_PREFERENCES}`,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    `${Routes.RECOMMENDATION_360}`,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.S3,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.EC2,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.RDS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.BIG_QUERY,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.GcpAppEngine,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.GcpCloudSQL,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.GcpComputeEngine,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.AZURE_VIRTUAL_MACHINES,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.AZURE_STORAGE,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.AZURE_DB_COSTS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.AzureActiveDirectory,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.AzureDataFactory,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.AZURE_RI_COVERAGE,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.K8S_PREVIEW,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.K8S_USAGE,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.K8S_PREFERENCES,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.Accounts],
    },
  ],
  [
    Routes.K8S_ASSETS,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.COST_GPT,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.DataAccess],
    },
  ],
  [
    Routes.PRICING,
    {
      notAllowedForUserTypes: [UsersType.USER_END_TRIAL],
      authorizationCategories: [HierarchicalEntityCategory.BillingData],
    },
  ],
]);

export const isRouteAllowed = ({
  route,
  isKeyCloakManagement,
  permissionCategories,
  deprecatedCurrentDisplayedUserType,
}) => {
  const { authorizationCategories, notAllowedForUserTypes } =
    mapRoutesAuthorizationCategories.get(cleanRoutePath(route)) || [];
  if (isKeyCloakManagement) {
    // check based on permissionCategories
    return (
      authorizationCategories?.includes('N/A') ||
      authorizationCategories?.some((category) => permissionCategories?.includes(category))
    );
  }
  // Legacy UM - check based on notAllowedForUserTypes
  return !notAllowedForUserTypes?.includes(deprecatedCurrentDisplayedUserType);
};
