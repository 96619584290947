import React, { useCallback, useEffect, useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import RowLikeAccordion from 'shared/components/RowLikeAccordion';
import { TableLikeHeader } from 'shared/components/TableLikeHeader.tsx';
import classNames from 'classnames';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { AlertTypeOnUpdate, AlertTypesEnum, CommitmentExpAlert } from '../types.ts';
import styles from '../alerts.module.scss';
import UpdateCommitmentModal from './UpdateCommitmentModal.tsx';
import t from '../texts.ts';
import { useAlerts } from '../hooks/useAlerts.ts';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal.tsx';
import { FormCommitmentExpiration } from './FormCommitmentExpiration.tsx';
import EditDeleteButtons from '../components/EditDeleteButtons.tsx';
import { isDefaultExpanded } from '../ServiceCostTab/constants.ts';
import { Recipient } from 'shared/components/RecipientsSelect';
import ShareEntityModal from 'shared/components/ShareEntityModal/ShareEntityModal';
import { Action, HierarchicalEntityCategory, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from '../../../../shared/components/ReadOnlyDisplayWrapper';

const PlusIconComponent = () => <GenerateIcon iconName={ICONS.plus.name} />;

const numberToAlertType = [
  'changeCostService',
  'commitementExpirationRi',
  'commitementExpirationSP',
] as AlertTypeOnUpdate[];

export interface CommitmentExpirationTabProps {
  spExpirationAlerts: CommitmentExpAlert[];
  riExpirationAlerts?: CommitmentExpAlert[];

  refetchAlerts(): void;
}

const CommitmentExpirationTab: React.FC<CommitmentExpirationTabProps> = ({
  riExpirationAlerts,
  spExpirationAlerts,
  refetchAlerts,
}) => {
  const { usersStore } = useRootStore();
  const [shouldHideEmailRecipients] = useState(usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE);
  const [isAllExpanded, setIsAllExpanded] = useState(isDefaultExpanded);
  const [isRIExpanded, setIsRIExpanded] = useState(isDefaultExpanded);
  const [isSPExpanded, setIsSPExpanded] = useState(isDefaultExpanded);
  const [showUpdateModal, setShowUpdateModal] = useState<null | AlertTypesEnum>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [activeAlert, setActiveAlert] = useState<CommitmentExpAlert | null>(null);
  const { updateAlerts, deleteAlert, checkSPPlansExist } = useAlerts();
  const { mutate: handleUpdateAlert } = updateAlerts(refetchAlerts);
  const { refetch: handleDelete } = deleteAlert(activeAlert?.uuid ?? '');
  const { refetch: refetchIsSpPlansExist } = checkSPPlansExist();
  const onCloseDeleteAlert = useCallback(() => setShowDeleteModal(false), []);
  const isAzure = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE;

  useEffect(() => {
    if (isAzure) {
      refetchIsSpPlansExist();
    }
  }, [isAzure, refetchIsSpPlansExist]);

  useEffect(() => {
    if (spExpirationAlerts?.length === 0) {
      setIsSPExpanded(false);
    }
    if (riExpirationAlerts?.length === 0) {
      setIsRIExpanded(false);
    }
  }, [spExpirationAlerts, riExpirationAlerts]);

  const onUpdate = useCallback(
    (
      daysBeforeExpiry: number,
      recipientsEmails: string,
      recipients: Recipient[],
      alertType: AlertTypesEnum,
      uuid?: string,
    ) => {
      const defaultTypeToCreate = AlertTypesEnum.RI_EXPIRATION;
      handleUpdateAlert([
        {
          uuid,
          daysBeforeExpiry,
          recipientsEmails,
          recipients,
          alertStatus: 'save',
          alertType: numberToAlertType[activeAlert?.alertType ?? alertType ?? defaultTypeToCreate],
        },
      ]);
      setShowUpdateModal(null);
    },
    [handleUpdateAlert, activeAlert],
  );

  const onCreateClick = useCallback(
    (newAlertType: AlertTypesEnum) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      setActiveAlert(null);
      setShowUpdateModal(newAlertType);
    },
    [],
  );

  const onEditClick = useCallback((alert: CommitmentExpAlert) => {
    setActiveAlert(alert);
    setShowUpdateModal(alert.alertType);
  }, []);

  const onDeleteClick = useCallback((alert: CommitmentExpAlert) => {
    setActiveAlert(alert);
    setShowDeleteModal(true);
  }, []);

  const onShareClick = (alert: CommitmentExpAlert) => {
    setActiveAlert(alert);
    setShowShareModal(true);
  };

  const onDeleteAlert = useCallback(() => {
    handleDelete();
    setTimeout(refetchAlerts, 1000);
    setShowDeleteModal(false);
  }, [handleDelete, refetchAlerts]);

  const onAllExpanded = useCallback(() => {
    setIsAllExpanded(!isAllExpanded);
    setIsRIExpanded(!isAllExpanded);
    setIsSPExpanded(!isAllExpanded);
  }, [isAllExpanded]);

  return (
    <div>
      <TableLikeHeader
        onExpandClick={onAllExpanded}
        title={t('ALERT_RULES')}
        createText={t('CREATE_ALERT_RULE')}
        isExpanded={isAllExpanded}
        totalNumber={(riExpirationAlerts?.length || 0) + (spExpirationAlerts.length || 0)}
        className={styles.marginBottom32}
        permissionsCategory={HierarchicalEntityCategory.Alerts}
      >
        <span className={styles.alertSwitcherLabel}>{`${t('SAVING_PLAN')}s(${spExpirationAlerts.length})`}</span>

        {riExpirationAlerts ? (
          <span className={styles.alertSwitcherLabel}>
            {`${t('RESERVED_INSTANCES')}(${riExpirationAlerts?.length})`}
          </span>
        ) : null}
      </TableLikeHeader>

      <RowLikeAccordion
        isExternalExpand={isSPExpanded && !!spExpirationAlerts.length}
        isExpandDisabled={!spExpirationAlerts.length}
        headerContent={t('SAVING_PLANS_EXPIRATION')}
        onExpand={(e, expanded) => setIsSPExpanded(expanded)}
        actionsBlock={
          <ReadOnlyDisplayWrapper
            isHide={false}
            category={OrganizationEntityCategory.CostAllocation}
            action={Action.Update}
          >
            <Button
              automationid="create-alert-button"
              isTextButton
              text={t('CREATE_ALERT_RULE')}
              onClick={onCreateClick(AlertTypesEnum.SP_EXPIRATION)}
              icon={PlusIconComponent}
            />
          </ReadOnlyDisplayWrapper>
        }
      >
        {spExpirationAlerts.map((alert) => (
          <div
            className={styles.alertsRow}
            automation-id={`${alert.uuid}alert-container`}
            key={`${alert.uuid}alert-container`}
          >
            <FormCommitmentExpiration
              className={classNames(styles.commitmentForm, styles.formDisabled)}
              daysBeforeExpiry={alert.daysBeforeExpiry}
              recipientsEmails={alert.recipientsEmails}
              recipients={alert.recipients}
              shouldHideEmailRecipients={shouldHideEmailRecipients}
              isSavingPlan
              key="alert-FormCommitmentExpiration"
            />
            <EditDeleteButtons
              style={{ marginTop: 13 }}
              onEdit={() => onEditClick(alert)}
              onDelete={() => onDeleteClick(alert)}
              onShare={() => onShareClick(alert)}
              alertId={alert.uuid}
              key="alert-EditDeleteButtons"
            />
          </div>
        ))}
      </RowLikeAccordion>

      <RowLikeAccordion
        isExternalExpand={!!(isRIExpanded && riExpirationAlerts?.length)}
        isExpandDisabled={!riExpirationAlerts?.length}
        headerContent={t('RESERVATIONS')}
        onExpand={(e, expanded) => setIsRIExpanded(expanded)}
        actionsBlock={
          <ReadOnlyDisplayWrapper
            isHide={false}
            category={OrganizationEntityCategory.CostAllocation}
            action={Action.Update}
          >
            <Button
              isTextButton
              text={t('CREATE_ALERT_RULE')}
              onClick={onCreateClick(AlertTypesEnum.RI_EXPIRATION)}
              icon={PlusIconComponent}
              automationid="create-alert-rule"
            />
          </ReadOnlyDisplayWrapper>
        }
      >
        {riExpirationAlerts?.map((alert) => (
          <div
            className={styles.alertsRow}
            automation-id={`${alert.uuid}alert-container`}
            key={`${alert.uuid}alert-container`}
          >
            <FormCommitmentExpiration
              className={classNames(styles.commitmentForm, styles.formDisabled)}
              daysBeforeExpiry={alert.daysBeforeExpiry}
              recipientsEmails={alert.recipientsEmails}
              recipients={alert.recipients}
              shouldHideEmailRecipients={shouldHideEmailRecipients}
              key={`${alert.uuid}alert-FormCommitmentExpiration`}
            />
            <EditDeleteButtons
              style={{ marginTop: 13 }}
              onEdit={() => onEditClick(alert)}
              onDelete={() => onDeleteClick(alert)}
              alertId={alert.uuid}
              onShare={() => onShareClick(alert)}
              key={`${alert.uuid}alert-EditDeleteButtons`}
            />
          </div>
        ))}
      </RowLikeAccordion>
      {showUpdateModal && (
        <UpdateCommitmentModal
          isEdit={Boolean(activeAlert)}
          shouldHideEmailRecipients={shouldHideEmailRecipients}
          alertType={showUpdateModal}
          onUpdate={onUpdate}
          onClose={() => setShowUpdateModal(null)}
          daysBeforeExpiry={activeAlert?.daysBeforeExpiry}
          recipientsEmails={activeAlert?.recipientsEmails}
          recipients={activeAlert?.recipients}
          uuid={activeAlert?.uuid}
        />
      )}
      {showShareModal && (
        <ShareEntityModal
          entity={HierarchicalEntityCategory.Alerts}
          entityId={activeAlert!.uuid}
          onClose={() => {
            setShowShareModal(false);
            setActiveAlert(null);
          }}
        />
      )}
      {showDeleteModal && <DeleteConfirmationModal onClose={onCloseDeleteAlert} onDelete={onDeleteAlert} />}
    </div>
  );
};

export default CommitmentExpirationTab;
