import { AddClusterFields, addClusterLabels } from '../config.ts';

export const validateAddClusterForm = (formState) => {
  let requiredFields = [
    AddClusterFields.linkedAccountId,
    AddClusterFields.clusterName,
    AddClusterFields.agentNamespace,
  ];
  const serverFields = [
    AddClusterFields.prometheusConnectingCreds_username,
    AddClusterFields.prometheusConnectingCreds_password,
    AddClusterFields.httpHeaders,
    AddClusterFields.kubeletJobName,
    AddClusterFields.kubeStateMetricsJobName,
    AddClusterFields.prometheusUrl,
  ];
  const showServer = formState[AddClusterFields.haveServer_block];

  if (showServer) {
    requiredFields = requiredFields.concat(serverFields);
  }

  const errors = {};
  requiredFields.forEach((field) => {
    if (!formState[field]) {
      errors[field] = addClusterLabels[field]?.error;
    }
  });
  if (showServer) {
    const headers = formState[AddClusterFields.httpHeaders].filter((d) => d.key && d.value);
    const uniqueHeaders = new Set(headers.map((d) => d.key));
    if (headers.length === 0) {
      errors[AddClusterFields.httpHeaders] = addClusterLabels[AddClusterFields.httpHeaders]?.error;
    } else if (headers.length > uniqueHeaders.size) {
      errors[AddClusterFields.httpHeaders] = 'Headers are duplicated';
    }

    if (!formState[AddClusterFields.otherMetrics_value]) {
      errors[AddClusterFields.otherMetrics_value] = addClusterLabels[AddClusterFields.otherMetrics_value]?.error;
    }
    if (!formState[AddClusterFields.otherMetrics_key]) {
      errors[AddClusterFields.otherMetrics_key] = addClusterLabels[AddClusterFields.otherMetrics_key]?.error;
    }
  }
  return errors;
};
