import moment from 'moment';
import { isEmailValid } from 'shared/utils/strUtil';
import { isEmpty } from 'lodash';
import { CREDIT_ERROR_MESSAGES, ALERT_ERROR_MESSAGES } from './creditConstants';
import { cloneDeep } from 'lodash';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { propComparator } from 'shared/utils/sortUtil';
import { serviceNameToDisplayNameWithShortName } from 'shared/constants/awsConstants';


export const currenciesFormatter = (value, getCurrencyNumber) =>
  !Number.isNaN(+value) && `${getCurrencyNumber(value, 2)}`;
export const isCreditAlert = (creditId, alerts) => !!alerts?.find((alert) => alert.creditId === creditId);
export const getCreditAlertByCreditId = (creditId, alerts) => alerts?.find((alert) => alert.creditId === creditId);

export const getCreditAlertByAlertId = (alertId, credits, alerts) =>
  credits?.find((credit) => alerts?.find((alert) => alertId === alert.uuid && alert.creditId === credit.id));

export const validateCredit = (credit, creditNames = []) => {
  const monthlyStartDate = moment(credit && credit.startDate).format('YYYY-MM');
  const monthlyExpDate = moment(credit && credit.expirationDate).format('YYYY-MM');
  const creditsValidationErrors = {};
  if (!credit || !credit.name) {
    creditsValidationErrors.name = CREDIT_ERROR_MESSAGES.EMPTY_NAME;
  }
  if (credit?.name?.length > 100) {
    creditsValidationErrors.name = CREDIT_ERROR_MESSAGES.TOO_LONG_NAME;
  }
  if (credit?.name && (creditNames || []).some((creditName) => creditName.trim() === credit.name.trim())) {
    creditsValidationErrors.name = CREDIT_ERROR_MESSAGES.DUPLICATE_NAME;
  }
  if (!credit || !credit.customers || !credit.customers.length) {
    creditsValidationErrors.customers = CREDIT_ERROR_MESSAGES.CUSTOMERS;
  }
  if (!credit || !credit.service || !credit.service.values || !credit.service.values.length) {
    creditsValidationErrors.service = CREDIT_ERROR_MESSAGES.SERVICE;
  }
  if (!credit || !credit.service || !credit.service.relation) {
    creditsValidationErrors.serviceRelation = CREDIT_ERROR_MESSAGES.SERVICE_RELATION;
  }
  if (!credit || !credit.amount || +credit.amount <= 0) {
    creditsValidationErrors.amount = CREDIT_ERROR_MESSAGES.AMOUNT;
  }
  if (!credit || (credit.monthlyAmountLimit && +credit.monthlyAmountLimit > +credit.amount)) {
    creditsValidationErrors.monthlyAmountLimit = CREDIT_ERROR_MESSAGES.MONTHLY_AMOUNT_LIMIT;
  }
  if (!credit || !credit.expirationDate || moment(monthlyStartDate).diff(moment(monthlyExpDate), 'months') > 0) {
    creditsValidationErrors.expirationDate = CREDIT_ERROR_MESSAGES.EXPIRATION_DATE;
  }
  if (!credit || !credit.startDate) {
    creditsValidationErrors.startDate = CREDIT_ERROR_MESSAGES.EMPTY_START_DATE;
  } else if (!credit || (moment().date() >= 20 && moment().diff(moment(credit.startDate), 'months') > 0)) {
    creditsValidationErrors.startDate = `${CREDIT_ERROR_MESSAGES.OLD_START_DATE} ${moment().format('MMM YYYY')}`;
  } else if (!credit || moment().diff(moment(credit.startDate), 'months') >= 2) {
    creditsValidationErrors.startDate = `${CREDIT_ERROR_MESSAGES.OLD_START_DATE} ${moment()
      .subtract(1, 'months')
      .format('MMM YYYY')}`;
  }
  return isEmpty(creditsValidationErrors) ? null : creditsValidationErrors;
};

export const validateAlert = (alert, creditAmount) => {
  const { recipientsEmails, alerts } = alert || {};
  const {
    utilization: { minAmount, minPercentage },
  } = alerts || { utilization: {} };
  const alertValidationErrors = {};
  if (!alert || !recipientsEmails || !recipientsEmails.split(',').filter((email) => email.trim()).length) {
    alertValidationErrors.recipientsEmails = ALERT_ERROR_MESSAGES.EMPTY_RECIPIENTS;
  } else if (
    recipientsEmails
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email)
      .some((email) => !isEmailValid(email))
  ) {
    alertValidationErrors.recipientsEmails = ALERT_ERROR_MESSAGES.INVALID_RECIPIENTS;
  }
  if (!alert || !minAmount || +minAmount <= 0 || +minAmount > +creditAmount) {
    alertValidationErrors.utilizationAmount = ALERT_ERROR_MESSAGES.UTILIZATION_AMOUNT;
  }
  if (!alert || !minPercentage || +minPercentage <= 0 || +minPercentage > 100) {
    alertValidationErrors.utilizationPercentage = ALERT_ERROR_MESSAGES.UTILIZATION_PERCENTAGE;
  }
  return isEmpty(alertValidationErrors) ? null : alertValidationErrors;
};

export const getServicesOptions = (services, cloudTypeId) => {
  const values = [...services].filter(Boolean);
  if (cloudTypeId === CLOUD_TYPE_IDS.AWS) {
    values.push(...['AWS Support [Business]', 'AWS Support [Developer]', 'AWS Premium Support']);
  }
  const options = values.map((value) => ({
    label: serviceNameToDisplayNameWithShortName.get(value) ?? value,
    value,
  }));
  if (options.length) {
    options.sort(propComparator('label'));
  }
  options.unshift({ value: 'all', label: 'All Services' });
  return options;
};

export const getFilteredData = (credits, input, fieldsForFilter = null) => {
  if (!input) {
    return cloneDeep(credits);
  }
  const filteredData = cloneDeep(credits).filter((credit) =>
    Object.entries(credit).some(([key, value]) => {
      if ((!fieldsForFilter || fieldsForFilter.includes(key)) && value) {
        const valueAsString = ((val) => {
          if (Array.isArray(val)) {
            return val.join(',');
          }
          if (typeof val === 'object') {
            return JSON.stringify(val);
          }
          return `${val}`;
        })(value);
        return valueAsString.toLowerCase().includes(input.toLowerCase());
      }
      return false;
    }),
  );
  return filteredData;
};
