import React, { useContext, useMemo } from 'react';
import styles from '../K8sOnboarding.module.scss';
import { OnboardingContext } from '../OnBoardingContext.tsx';
import FilesDownload from 'shared/components/andtComponents/FilesDownload';
import { AddClusterFields } from '../config.ts';
import { generateK8YAMLFile } from './generateK8YAMLFile.ts';
import { useRootStore } from 'app/contexts/RootStoreContext';

const fileExtension = 'yaml';

const DownloadFiles: React.FC<{ onDownload(): void }> = () => {
  const { addedClusters } = useContext(OnboardingContext);
  const {
    usersStore: { currDispUserCloudAccountType, currDispUserAccountKey, getCurrentDisplayedAccountName },
  } = useRootStore();
  const accountName = getCurrentDisplayedAccountName(currDispUserAccountKey);
  const filesToLoad = useMemo(() => {
    return addedClusters.map((cluster) => ({
      title: `${cluster[AddClusterFields.clusterName]}.${fileExtension}`,
      fileName: `${cluster[AddClusterFields.clusterName]}.${fileExtension}`,
      jsonGen: () => generateK8YAMLFile(cluster, currDispUserCloudAccountType, accountName),
      nodeId: cluster.id,
    }));
  }, [accountName, addedClusters, currDispUserCloudAccountType]);
  return (
    <div className={styles.downloadFiles}>
      <h5 className={'mb-2'}>Download Your Cluster YAML Files</h5>
      <FilesDownload
        disabled={!filesToLoad.length}
        buttonTitle="Download Files"
        files={filesToLoad}
        fileExtension={fileExtension}
      />
    </div>
  );
};

export default DownloadFiles;
