import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './TableComponents.module.scss';

/**
 * Custom header cell component that uses the tooltip property from column configuration.
 * Provides custom tooltips for columns that have a tooltip property set.
 * For columns without a custom tooltip, the default sorting behavior is maintained.
 * 
 * Usage:
 * 1. Add a 'tooltip' property to your column definition object
 * 2. Use this component as the cellComponent prop for TableHeaderRow:
 *    <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
 */

// Using 'any' for DevExpress types since they are complex and we're focused on the tooltip functionality
type CustomHeaderCellProps = {
  column: {
    tooltip?: string;
    [key: string]: any;
  };
  children?: React.ReactNode;
  [key: string]: any;
};

const CustomHeaderCell: React.FC<CustomHeaderCellProps> = (props) => {
  const { column, children, ...restProps } = props;
  
  if (column.tooltip) {
    return (
      // @ts-ignore - DevExpress types are complex
      <TableHeaderRow.Cell {...restProps} column={column}>
        <Tooltip title={column.tooltip}>
          <span className={styles.headerList}>{children}</span>
        </Tooltip>
      </TableHeaderRow.Cell>
    );
  }
  
  // @ts-ignore - DevExpress types are complex
  return <TableHeaderRow.Cell {...restProps} column={column}>{children}</TableHeaderRow.Cell>;
};

export default CustomHeaderCell; 