import { PERMISSION_TYPES } from '~/users/containers/Organization/consts.js';

export function getPermissionsOfDuplicatedRole(permissions) {
  const result = permissions
    ?.map((category) => {
      const filteredActions = category.actionPermissions
        ?.map((action) => {
          let filteredPermissions = action.permissions?.filter((perm) => perm.derivedFromRole === null);
          filteredPermissions = filteredPermissions?.map((p) =>
            p.permissionType === PERMISSION_TYPES.PARTIAL.id ? { ...p, permissionType: PERMISSION_TYPES.NONE.id } : p,
          );
          return filteredPermissions.length > 0 ? { ...action, permissions: filteredPermissions } : null;
        })
        .filter((action) => action !== null);

      return filteredActions.length > 0 ? { ...category, actionPermissions: filteredActions } : null;
    })
    .filter((category) => category !== null);
  return result;
}

export function mergePermissions(original, filtered) {
  const merged = [...original];

  filtered.forEach((filteredCategory) => {
    const existingCategory = merged.find((c) => c.category === filteredCategory.category);

    if (existingCategory) {
      filteredCategory.actionPermissions.forEach((filteredAction) => {
        const existingAction = existingCategory.actionPermissions.find((a) => a.action === filteredAction.action);

        if (existingAction) {
          existingAction.permissions.push(...filteredAction.permissions);
        } else {
          existingCategory.actionPermissions.push(filteredAction);
        }
      });
    } else {
      merged.push(filteredCategory);
    }
  });

  return merged;
}
