import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import Spinner from 'shared/components/andtComponents/Spinner';
import EffectivePermissions from '../../../components/EffectivePermissions/EffectivePermissions';
import {
  getPermissionsOfDuplicatedRole,
  mergePermissions,
} from 'users/containers/Organization/Roles/roleModals/CreateEditRoleModal/duplicateRoleEffectivePermissionsUtil';

import styles from './CreateEditRoleModal.module.scss';

const EffectivePermissionsStep = ({ isViewOnly, roleId, role, setRole, isDuplicate, setRoleOnCreateEdit }) => {
  const { fetchRoleById } = useRoles();
  const { data: roleData, isLoading } = fetchRoleById(role.id);

  // Only if this is a duplicate of role step (and not create) - make the second request to get the role data of duplicated role
  const { fetchRoleById: fetchForDuplicate } = useRoles(isDuplicate);
  const { data: roleDataForDuplicate, isLoading: isLoadingForDuplicate } = fetchForDuplicate(roleId);

  const firstTime = useRef(true);

  const rolePermissions = useMemo(() => {
    if (isLoading || (isDuplicate && isLoadingForDuplicate)) {
      return;
    }
    let tmpPermissions = roleData.permissions?.permissions;
    if (!isDuplicate || !roleDataForDuplicate?.permissions?.permissions?.length) {
      return tmpPermissions;
    } else {
      const duplicateRoleDirectPermissions = getPermissionsOfDuplicatedRole(
        roleDataForDuplicate.permissions.permissions,
      );
      tmpPermissions = mergePermissions(tmpPermissions, duplicateRoleDirectPermissions);
      return tmpPermissions;
    }
  }, [isLoading, isDuplicate, isLoadingForDuplicate, roleData?.permissions?.permissions, roleDataForDuplicate]);

  useEffect(() => {
    if (roleData) {
      if (firstTime.current) {
        setRoleOnCreateEdit((prev) => ({ ...prev, permissions: cloneDeep(roleData.permissions?.permissions || []) }));
        firstTime.current = false;
      }
      setRole((prev) => ({ ...prev, permissions: cloneDeep(rolePermissions) }));
    }
  }, [roleData, rolePermissions, setRole, setRoleOnCreateEdit]);

  const onPermissionChange = (categoryNewPermissions, categoryPermissions) => {
    const roleWithNewPermissions = { ...(role || {}), permissions: role.permissions || [] };
    const roleCategory = roleWithNewPermissions?.permissions?.find(
      (p) => p?.category === categoryNewPermissions?.category,
    );
    const roleCategoryPermissions = roleCategory ?? categoryPermissions;
    const roleCategoryActionPermissionsIndex = roleCategoryPermissions.actionPermissions?.findIndex(
      (actionPermission) => actionPermission.action === categoryNewPermissions.actionPermissions[0].action,
    );
    if (roleCategoryActionPermissionsIndex === -1) {
      roleCategoryPermissions.actionPermissions.push(categoryNewPermissions.actionPermissions[0]);
    } else {
      roleCategoryPermissions.actionPermissions = [
        ...roleCategoryPermissions.actionPermissions.slice(0, roleCategoryActionPermissionsIndex),
        categoryNewPermissions.actionPermissions[0],
        ...roleCategoryPermissions.actionPermissions.slice(roleCategoryActionPermissionsIndex + 1),
      ];
    }
    setRole((prev) => ({
      ...prev,
      permissions: [
        ...(prev.permissions ? prev.permissions.filter((p) => p.category !== roleCategoryPermissions.category) : []),
        roleCategoryPermissions,
      ],
    }));
  };

  const onActionForAllCategoriesPermissionChanged = (categoriesNewPermissions) => {
    categoriesNewPermissions.forEach((categoryNewPermissions) => {
      const categoryInitialPermissions = role?.permissions.find(
        (categoryPermissions) => categoryPermissions.category === categoryNewPermissions.category,
      );
      onPermissionChange(categoryNewPermissions, categoryInitialPermissions);
    });
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.effectivePermissionsStepContainer}>
      <EffectivePermissions
        isCreateEditMode={false}
        isViewOnly={isViewOnly}
        permissions={role?.permissions}
        roleId={role?.subRoles?.[0]?.id}
        permissionChanged={onPermissionChange}
        actionPermissionForAllCategoriesChanged={onActionForAllCategoriesPermissionChanged}
      />
    </div>
  );
};

EffectivePermissionsStep.propTypes = {
  isDuplicate: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  role: PropTypes.shape({
    id: PropTypes.string,
    subRoles: PropTypes.array,
    permissions: PropTypes.array,
  }),
  roleId: PropTypes.string, // needed in addition roleId in case this is duplicate of role
  setRole: PropTypes.func.isRequired,
  setRoleOnCreateEdit: PropTypes.func.isRequired,
};

export default EffectivePermissionsStep;
