import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import classNames from 'classnames';
import { Search } from '../Search';
import RemoveButton from '../RemoveButton';
import { ENTITIES } from '../../consts';
import { OrganizationEntityCategory, Action } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { countSelectedLinkedAccounts } from './dataAccessHelperFunctions';
import FullAccessCheckbox from 'users/containers/Organization/Roles/rolesExpandedRowComponents/DataAccess/FullAccessCheckbox';

import styles from './LinkedAccountsComponent.module.scss';

const LinkedAccountsComponentHeader = ({
  allAccountsData,
  isCreateEditMode,
  isSelectAll,
  isViewOnly,
  modifiedSelectionById,
  onRemoveSelected,
  onSelectAll,
  onFullAccessSelectChanged,
  search,
  setSearch,
  entity,
}) => {
  const fullAccessData = allAccountsData?.fullAccessData;

  const countOfAllSelectedLinkedAccounts = useMemo(() => {
    const allAccountsFlat = allAccountsData?.accountsData
      ? Object.values(allAccountsData.accountsData).flatMap((account) => account)
      : [];
    return countSelectedLinkedAccounts(allAccountsFlat, modifiedSelectionById);
  }, [allAccountsData, modifiedSelectionById]);

  const countOfAllLinkedAccounts = useMemo(() => {
    let totalLinkedAccounts = 0;
    if (allAccountsData?.totalLinkedAccountsByCloudProvider) {
      Object.values(allAccountsData.totalLinkedAccountsByCloudProvider)?.forEach((counter) => {
        const num = Number(counter);
        if (Number.isFinite(num)) {
          totalLinkedAccounts += counter;
        }
      });
    }
    return totalLinkedAccounts;
  }, [allAccountsData.totalLinkedAccountsByCloudProvider]);

  const counterRef = useRef(null);

  useEffect(() => {
    if (isCreateEditMode && counterRef?.current) {
      // Calculate the max possible width of the counter element, so the Select All will not move when on counter change
      const tempDiv = document.createElement('div');
      tempDiv.style.visibility = 'hidden';
      tempDiv.style.position = 'absolute';
      tempDiv.style.whiteSpace = 'nowrap';

      const counterStyles = window.getComputedStyle(counterRef.current);
      tempDiv.style.font = counterStyles.font;

      tempDiv.textContent = `${countOfAllLinkedAccounts}/${countOfAllLinkedAccounts}`;
      document.body.appendChild(tempDiv);

      const maxWidth = tempDiv.offsetWidth;
      counterRef.current.style.minWidth = `${maxWidth + 2}px`;

      document.body.removeChild(tempDiv);
    }
  }, [countOfAllLinkedAccounts, isCreateEditMode]);

  const SelectAllContent = () => (
    <div className={styles.selectAll}>
      <div>Select All</div>
      <InfoPopover className={{ icon: classNames('d-flex', 'align-items-center') }}>
        {`Note that accounts assigned from other ${
          Object.values(ENTITIES).find((e) => e.id === entity).label
        }s will not be ${isCreateEditMode ? 'added' : 'removed'}`}
      </InfoPopover>
    </div>
  );

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        {isCreateEditMode ? (
          <div className={styles.headerTitle}>
            Selected Total
            <div className={styles.headerCounters} ref={counterRef}>
              <span className={styles.selectedCounter}>{countOfAllSelectedLinkedAccounts}</span>/
              <span>{countOfAllLinkedAccounts}</span>
            </div>
          </div>
        ) : null}
        {entity !== ENTITIES.CUSTOMER.id && (
          <>
            {isViewOnly ? null : (
              <Checkbox
                isChecked={isSelectAll}
                isDisabled={isViewOnly || fullAccessData?.isFullAccess}
                onChange={() => onSelectAll(!isSelectAll)}
                primary
                isSimple
                text={SelectAllContent()}
              />
            )}
            {entity === ENTITIES.ROLE.id && (fullAccessData?.isFullAccess || isCreateEditMode) ? (
              <FullAccessCheckbox
                isDisabled={isViewOnly || !isCreateEditMode || fullAccessData?.isFullAccessInherited}
                isFullAccess={fullAccessData?.isFullAccess}
                onFullAccessSelectChanged={onFullAccessSelectChanged}
              />
            ) : null}
          </>
        )}
      </div>
      <div className={classNames(styles.headerRight, { [styles.createEdit]: isCreateEditMode })}>
        {isCreateEditMode ? null : (
          <ReadOnlyDisplayWrapper isHide={false} category={OrganizationEntityCategory.Roles} action={Action.Update}>
            <RemoveButton
              showRemoveComponent={countOfAllSelectedLinkedAccounts > 0}
              selectedCounter={countOfAllSelectedLinkedAccounts}
              removeClicked={onRemoveSelected}
              isMultiple
            />
          </ReadOnlyDisplayWrapper>
        )}
        {entity !== ENTITIES.CUSTOMER.id && <Search search={search} setSearch={setSearch} isDebounce />}
      </div>
    </div>
  );
};

LinkedAccountsComponentHeader.propTypes = {
  allAccountsData: PropTypes.object.isRequired,
  isCreateEditMode: PropTypes.bool.isRequired,
  isSelectAll: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool.isRequired,
  modifiedSelectionById: PropTypes.object.isRequired,
  onRemoveSelected: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  entity: PropTypes.string.isRequired,
};

export default LinkedAccountsComponentHeader;
