import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Sidebar } from '@pileus-cloud/anodot-frontend-common';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import { usePermissionCategories } from 'usage/hooks/react-query/usePermissionCategories';
import { getSidebarItems } from 'shared/constants/routesMapping';

// Maintain this list to control which menu items should have the new indication
const newMenus = [
  {
    menu: 'spAnalyzerNew',
    ff: ACCOUNT_FEATURES.SP_ANALYZER_NEW,
  },
  {
    menu: 'billingRulesNew',
    ff: ACCOUNT_FEATURES.BILLING_RULES_NEW,
  },
  {
    menu: 'billingSummary',
    ff: ACCOUNT_FEATURES.BILLING_SUMMARY,
  },
];

const SidebarContent = ({ usersStore, appStore }) => {
  const [sidebarOptions, setSidebarOptions] = useState([]);
  const { fetchAccessibleCategories } = usePermissionCategories();
  const { data: permissionCategories } = fetchAccessibleCategories();
  const navigate = useNavigate();
  const location = useLocation();

  // Add new indications
  const enrichMenuOptionsData = useCallback(
    (menuOptions) => {
      const currentRouteUrl = location.pathname;
      return (menuOptions || []).map((parentMenu) => ({
        ...parentMenu,
        isNew: newMenus.some(
          (newMenu) => newMenu.menu === parentMenu.value && (!newMenu.ff || checkFeatureFlag(usersStore, newMenu.ff)),
        ),
        isActive:
          (parentMenu.route && parentMenu.route === currentRouteUrl) ||
          [parentMenu.children || []].some((child) => child.route === currentRouteUrl),
        children: (parentMenu.children || []).map((childMenu) => ({
          ...childMenu,
          isActive: childMenu.route === currentRouteUrl,
          isNew: newMenus.some(
            (newMenu) => newMenu.menu === childMenu.value && (!newMenu.ff || checkFeatureFlag(usersStore, newMenu.ff)),
          ),
        })),
      }));
    },
    [location.pathname, usersStore],
  );

  // Update menu options when any of the affecting params change
  useEffect(() => {
    const menuOptions = getSidebarItems(usersStore, appStore, permissionCategories);
    const updatedMenu = enrichMenuOptionsData(menuOptions);
    setSidebarOptions(updatedMenu);
  }, [
    appStore,
    enrichMenuOptionsData,
    usersStore,
    location.pathname,
    permissionCategories,
    usersStore.currDispUserAccountKey,
    usersStore.currentDisplayedUserKey,
    usersStore.currDispUserCloudAccountType,
    usersStore.deprecatedGetCurrentDisplayedUserType,
  ]);

  const findMenuItem = useCallback(
    (item) => {
      if (item) {
        const index = item.indexOf('/');
        let parentItem = item;
        let childItem = null;
        let found;

        if (index > -1) {
          // The selected item is a child inside a parent.
          parentItem = item.slice(0, index);
          childItem = item.slice(index + 1);
          found = sidebarOptions.find((i) => i.value === parentItem)?.children?.find((j) => j.value === childItem);
        } else {
          found = sidebarOptions.find((i) => i.value === parentItem);
        }
        return found;
      }
      return null;
    },
    [sidebarOptions],
  );

  const handleOnValueChange = useCallback(
    (item, isOpenNewWindow = false) => {
      const selectedMenuItem = findMenuItem(item);
      if (selectedMenuItem) {
        const url = selectedMenuItem.route;
        if (isOpenNewWindow || url?.startsWith('http')) {
          // Open menu link in new window
          window.open(url, '_blank');
          return;
        }
        navigate(url);
      }
    },
    [findMenuItem, history],
  );

  return <Sidebar options={sidebarOptions} onValueChange={handleOnValueChange} />;
};

SidebarContent.propTypes = {
  usersStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
};

export default SidebarContent;
