import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import commonStyles from 'app/containers/Onboarding/AwsOnboarding/pages/Pages.module.scss';
import styles from '../K8sOnboarding.module.scss';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { v4 } from 'uuid';
import { HeaderRecord } from '../types.ts';

const deleteIcon = () => <GenerateIcon iconName={ICONS.deleteRegular.name} />;

type Props = {
  setHeaders(value: unknown): void;
  headers: HeaderRecord[];
  error?: string;
};

const HttpRequestsField: React.FC<Props> = ({ setHeaders, headers, error }) => {
  const onAddRow = useCallback(() => setHeaders([...headers, { key: '', value: '', id: v4() }]), [setHeaders, headers]);

  const onDeleteRow = useCallback(
    (idToDelete: string) => {
      setHeaders(headers.filter((header) => header.id !== idToDelete));
    },
    [setHeaders, headers],
  );

  const onChange = useCallback(
    (idToUpdate: string, fieldName: string, newValue: string) => {
      const currentHeader = { ...(headers.find((header) => header.id === idToUpdate) || {}) };
      currentHeader[fieldName] = newValue;
      const updatedHeaders = headers.map((header) => {
        if (header.id === idToUpdate) {
          const newHeader = { ...header };
          newHeader[fieldName] = newValue;
          return newHeader;
        }
        return header;
      });
      setHeaders(updatedHeaders);
    },
    [setHeaders, headers],
  );

  useEffect(() => {
    onAddRow();
  }, [onAddRow]);

  return (
    <div className={commonStyles.inputsNestedContainer}>
      {headers.map(({ id, key, value }) => (
        <div key={id} className={classNames('d-flex align-items-center mb-2')}>
          <div className={commonStyles.inputBox}>
            <label htmlFor={`${id}_key`}>Key</label>
            <Input
              className={styles.noMinWidth}
              name={`${id}_key`}
              value={key}
              onChange={(e) => onChange(id, 'key', e.target.value)}
            />
          </div>
          <div className={classNames(commonStyles.inputBox, 'ms-3')}>
            <label htmlFor={`${id}_value`}>Value</label>
            <Input
              className={styles.noMinWidth}
              name={`${id}_value`}
              value={value}
              onChange={(e) => onChange(id, 'value', e.target.value)}
            />
          </div>
          <Button
            overrideStyles={{ color: 'gray', marginTop: 20, marginLeft: 10 }}
            onClick={() => onDeleteRow(id)}
            isTextButton={true}
            icon={deleteIcon}
          />
        </div>
      ))}
      {error && <span className={styles.errorLabel}>{error}</span>}
      <Button overrideStyles={{ marginRight: 'auto' }} text={'+ Add More'} isTextButton onClick={onAddRow} />
    </div>
  );
};

export default HttpRequestsField;
