import React from 'react';
import { Container, Row } from 'reactstrap';
import illustration from './assets/illustration.png';
import styles from './TrialEnded.module.scss';
import { useBrand } from '../../contexts/Brand/BrandContext.tsx';

const NotAllowedPage = () => {
  const brand = useBrand();
  return (
    <Container>
      <Row>
        <div className={styles.container}>
          <img src={illustration} alt="No permissions" />
          <h4 className={styles.title}>The trial period has ended, would you like to join us?</h4>
          <div className={styles.content}>
            Join us and get access to recommendations, anomaly detection, cost forecasting, and budgets, as well as push
            alerts and reports to help you monitor and control your cloud costs.
            <br />
            We’ll be happy to see you!
            <br />
            <br />
            The {brand.name} Cost team
            <br />
            <a href={`mailto:${brand.infoEmail}`}>{brand.infoEmail}</a>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default NotAllowedPage;
