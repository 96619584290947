import React from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import PropTypes from 'prop-types';
import { copyStrToClipboard } from 'shared/utils/strUtil';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import Button from 'shared/components/andtComponents/Button';
import styles from './Notification.module.scss';

const Notification = ({ notification, checked, onChecked }) => {
  const copyToClipboard = () => {
    copyStrToClipboard(notification.details.id);
  };
  const { title, subTitle, icon, description } = notification;
  if (!title) {
    return null;
  }
  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {notification.excludable && (
          <Checkbox primary isChecked={checked} onChange={() => onChecked(!checked)} text="" />
        )}
        {title}
      </p>
      {subTitle && (
        <p className={styles.subTitle}>
          {icon ? <GenerateIcon iconName={ICONS[icon].name} /> : null}
          <span>{subTitle}</span>
          {notification.details.id && (
            <Button
              isTextButton
              icon={() => <GenerateIcon iconName={ICONS.clone.name} />}
              text=""
              onClick={copyToClipboard}
            />
          )}
        </p>
      )}
      <p className={styles.description}>{description}</p>
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  onChecked: PropTypes.func.isRequired,
};

export default Notification;
