import { ICONS } from '@pileus-cloud/anodot-frontend-common';
import { FILTER_OPERATIONS } from 'shared/components/andtComponents/TableComponents/const.js';
import moment from 'moment';
import { debounce } from 'lodash';

export const STALE_TIME = 60 * 60 * 1000;

export const DEFAULT_PAGE_SIZE = 50;

export const PAGINATION_DIRECTION = { NEXT: 1, PREV: -1 };

export const ENTITIES = {
  ROLE: { id: 'role', label: 'role' },
  CUSTOMER: { id: 'customer', label: 'customer' },
  COST_CENTER: { id: 'costCenter', label: 'cost center' },
};

export const TAB_PATHS = {
  USERS: 'users',
  ROLES: 'roles',
  CUSTOMERS: 'customers',
  COST_CENTERS: 'cost-centers',
  CLOUD_ACCOUNTS: 'cloud-accounts',
  CHANNELS: 'channels',
  LINKED_ACCOUNTS: 'linked-accounts',
  SETTINGS: 'settings',
  AZURE_APP_REG: 'app-registration',
};

export const ACCOUNT_TYPES = {
  LINKED_ACCOUNT: 'linkedAccount',
  PAYER_ACCOUNT: 'payerAccount',
};

export const getIdWithType = (accountId, accountType) => `${accountType}_${accountId}`;

export const DATA_ACCESS_TOOLTIPS = {
  // eslint-disable-next-line max-len
  ACCOUNTS_TOOLTIP: `\u2022 When assigning a payer account to a role, all current and future linked accounts included in the payer account will be assigned to the role.
    \u2022 Check the full access box to provide access to all accounts, now and in the future.`,
  COST_CENTERS_TOOLTIP:
    'When assigning a Cost Center to a role, all current and future linked accounts included in the Cost Center will be assigned to the role.',
};

export const DATA_ACCESS_TABS = {
  ACCOUNTS: {
    id: 'accounts',
    label: 'Accounts',
    tooltip: DATA_ACCESS_TOOLTIPS.ACCOUNTS_TOOLTIP,
  },
  COST_CENTERS: {
    id: 'costCenters',
    label: 'Cost Centers',
    tooltip: DATA_ACCESS_TOOLTIPS.COST_CENTERS_TOOLTIP,
  },
};

export const USER_STATUS_OPTIONS = { ENABLED: 'Enabled', DISABLED: 'Disabled' };

export const USERS_COLUMNS = {
  USER_NAME: {
    columnName: 'username',
    title: 'User Name',
    width: '20%',
    sortingEnabled: false, // Sorting is not supported by KeyCloak. The results will be sorted by username ascending.
    direction: 'asc',
  },
  FIRST_NAME: {
    columnName: 'firstName',
    title: 'First Name',
    width: '8%',
    sortingEnabled: false,
  },
  LAST_NAME: {
    columnName: 'lastName',
    title: 'Last Name',
    width: '8%',
    sortingEnabled: false,
  },
  CUSTOMER: {
    columnName: 'customer',
    title: 'Customer',
    width: '10%',
    sortingEnabled: false,
  },
  ROLES: {
    columnName: 'roles',
    title: 'Roles',
    width: '10%',
    sortingEnabled: false,
  },
  CREATED_AT: {
    columnName: 'createdAt',
    title: 'Created At',
    width: '10%',
    sortingEnabled: false,
  },
  LAST_LOGIN: {
    columnName: 'lastLogin',
    title: 'Last Login',
    width: '10%',
    sortingEnabled: false,
  },
  USER_STATUS: {
    columnName: 'userStatus',
    title: '',
    width: 170,
    sortingEnabled: false,
    align: 'right',
  },
  IMPERSONATE: {
    columnName: 'impersonate',
    title: '',
    width: 30,
    sortingEnabled: false,
    align: 'right',
  },
  ACTIONS: {
    columnName: 'actions',
    title: '',
    width: 40,
    sortingEnabled: false,
    align: 'right',
  },
};

export const USER_ROLES_COLUMNS = {
  ROLE_NAME: {
    columnName: 'displayName',
    title: 'Role Name',
    width: '30%',
    direction: 'desc',
    sortingEnabled: true,
  },
  ASSIGNED_AT: {
    columnName: 'assignedAt',
    title: 'Assigned At',
    width: '20%',
    sortingEnabled: false,
  },
  ASSIGNED_BY: {
    columnName: 'assignedBy',
    title: 'Assigned By',
    width: '20%',
    sortingEnabled: false,
  },
  REMOVE_USER_ROLE: {
    columnName: 'removeUserRole',
    title: '',
    width: '30%',
    align: 'right',
    sortingEnabled: false,
  },
};

export const USER_ACTIONS = {
  ASSIGN_ROLES: {
    id: 'assignRoles',
    label: 'Assign Roles',
    icon: ICONS.userGear.name,
    automationid: 'userMenuActions_AssignRole',
  },
  EDIT: { id: 'edit', label: 'Edit', icon: ICONS.edit.name, automationid: 'userMenuActions_Edit' },
  RESET_PASSWORD: {
    id: 'resetPassword',
    label: 'Reset Password',
    icon: ICONS.refresh.name,
    automationid: 'userMenuActions_ResetPassword',
  },
  DELETE: { id: 'delete', label: 'Delete', icon: ICONS.deleteRegular.name, automationid: 'userMenuActions_Delete' },
};

export const ROLES_COLUMNS = {
  ROLE_NAME: {
    columnName: 'displayName',
    title: 'Role Name',
    width: '14%',
    sortingEnabled: false,
    getCellValue: (row) => row?.displayName,
    direction: 'asc',
    placeholder: 'Role Name',
  },
  ROLE_ID: {
    columnName: 'roleReferenceId',
    title: 'Role Reference Id',
    width: '12%',
    sortingEnabled: false,
    getCellValue: (row) => row?.roleReferenceId,
  },
  USERS_COUNT: {
    columnName: 'usersCount',
    title: 'Users',
    width: '3%',
    sortingEnabled: false,
  },
  CREATED_AT: {
    columnName: 'createdAt',
    title: 'Created At',
    width: '10%',
    sortingEnabled: false,
  },
  CREATED_BY: {
    columnName: 'createdBy',
    title: 'Created By',
    width: '10%',
    sortingEnabled: false,
  },
  UPDATED_AT: {
    columnName: 'updatedAt',
    title: 'Updated At',
    width: '10%',
    sortingEnabled: false,
  },
  UPDATED_BY: {
    columnName: 'updatedBy',
    title: 'Updated By',
    width: '10%',
    sortingEnabled: false,
  },
  ROLE_DESCRIPTION: {
    columnName: 'description',
    title: 'Description',
    width: '14%',
    sortingEnabled: false,
    placeholder: 'Description',
  },
  DUPLICATE_ROLE: {
    columnName: 'duplicateRole',
    title: '',
    width: 50,
    sortingEnabled: false,
    align: 'right',
  },
  ADD_LINKED_ACCOUNTS: {
    columnName: 'addLinkedAccounts',
    title: '',
    width: 50,
    sortingEnabled: false,
    align: 'right',
  },
  ADD_USERS: {
    columnName: 'addUsers',
    title: '',
    width: 50,
    sortingEnabled: false,
    align: 'right',
  },
  ACTIONS: {
    columnName: 'actions',
    title: '',
    width: 30,
    sortingEnabled: false,
    align: 'right',
  },
};

export const ADD_USERS_TO_ROLE_MODAL_COLUMNS = {
  USER_NAME: { ...USERS_COLUMNS.USER_NAME, width: 200 },
  FIRST_NAME: { ...USERS_COLUMNS.FIRST_NAME, width: 100 },
  LAST_NAME: { ...USERS_COLUMNS.LAST_NAME, width: 100 },
};

export const ADD_ROLES_MODAL_COLUMNS = {
  ROLE_NAME: { ...ROLES_COLUMNS.ROLE_NAME, width: '30%' },
  ROLE_ID: { ...ROLES_COLUMNS.ROLE_ID, width: '30%' },
  ROLE_DESCRIPTION: { ...ROLES_COLUMNS.ROLE_DESCRIPTION, width: '40%' },
};

export const ROLE_USERS_COLUMNS = {
  USER_NAME: { ...USERS_COLUMNS.USER_NAME, width: 200 },
  FIRST_NAME: { ...USERS_COLUMNS.FIRST_NAME, width: 100 },
  LAST_NAME: { ...USERS_COLUMNS.LAST_NAME, width: 100 },
  ROLES: { ...USERS_COLUMNS.ROLES, width: 200 },
  CREATED_AT: { ...USERS_COLUMNS.CREATED_AT, width: 170 },
  LAST_LOGIN: { ...USERS_COLUMNS.LAST_LOGIN, width: 170 },
  USER_STATUS: {
    columnName: 'userStatus',
    title: 'Status',
    width: 170,
    sortingEnabled: false,
  },
  REMOVE_USER_ROLE: {
    columnName: 'removeRoleUser',
    title: '',
    width: 200,
    align: 'right',
    sortingEnabled: false,
  },
};

export const ROLE_SUB_ROLES_COLUMNS = {
  ROLE_NAME: { ...ROLES_COLUMNS.ROLE_NAME, getCellValue: (row) => row?.displayName, width: 200 },
  CREATED_AT: { ...ROLES_COLUMNS.CREATED_AT, width: 170 },
  CREATED_BY: { ...ROLES_COLUMNS.CREATED_BY, width: 170 },
  UPDATED_AT: { ...ROLES_COLUMNS.UPDATED_AT, width: 170 },
  UPDATED_BY: { ...ROLES_COLUMNS.UPDATED_BY, width: 170 },
  REMOVE_SUB_ROLE: {
    columnName: 'removeRoleSubRole',
    title: '',
    width: 200,
    align: 'right',
    sortingEnabled: false,
  },
};

export const ROLE_ACTIONS = {
  CREATE: {
    id: 'create',
    label: 'Create Role',
    action: 'Create',
    icon: ICONS.plus.name,
    automationid: 'roleMenuActions_Create',
  },
  EDIT: {
    id: 'edit',
    label: 'Rename Role',
    action: 'Rename',
    icon: ICONS.edit.name,
    automationid: 'roleMenuActions_Edit',
  },
  VIEW: {
    id: 'view',
    label: 'View Role',
    action: 'View',
    icon: ICONS.eye.name,
    automationid: 'roleMenuActions_View',
  },
  DELETE: {
    id: 'delete',
    action: 'Delete',
    label: 'Delete Roles',
    icon: ICONS.deleteRegular.name,
    automationid: 'roleMenuActions_Delete',
  },
};

export const LINKED_ACCOUNTS_BY_CLOUD_TYPE_COLUMNS = {
  CLOUD_TYPE: { columnName: 'cloudType', title: '', width: '100%' },
};

export const ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS = {
  PAYER_ACCOUNT: {
    columnName: 'payerAccount',
    title: 'Payer Account',
    width: 200,
    getCellValue: (row) => row?.payerAccount?.name,
  },
  ASSIGNED_BY: {
    columnName: 'assignedBy',
    title: '',
    width: 200,
    getCellValue: (row) => row?.derivedFromRole?.displayName,
  },
  REMOVE_PAYER_ACCOUNT: {
    columnName: 'removePayerAccount',
    title: '',
    width: 200,
    align: 'right',
    sortingEnabled: false,
  },
};

export const ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS = {
  LINKED_ACCOUNT: {
    columnName: 'linkedAccountNameAndId',
    title: 'Account Name & ID',
    width: 200,
    sortingEnabled: false,
    direction: 'desc',
    getCellValue: (row) => row?.name,
  },
  ASSIGNED_BY: {
    columnName: 'assignedBy',
    title: '',
    width: 200,
    getCellValue: (row) => row?.derivedFromRole?.displayName,
  },
  REMOVE_LINKED_ACCOUNT: {
    columnName: 'removeLinkedAccount',
    title: '',
    width: 200,
    align: 'right',
    sortingEnabled: false,
  },
};

export const ROLE_ADD_REMOVE_PAYER_ACCOUNTS_COLUMNS = {
  PAYER_ACCOUNT: ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS.PAYER_ACCOUNT,
  ASSIGNED_BY: ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS.ASSIGNED_BY,
};

export const ROLE_ADD_REMOVE_LINKED_ACCOUNTS_COLUMNS = {
  LINKED_ACCOUNT: ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS.LINKED_ACCOUNT,
  ASSIGNED_BY: ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS.ASSIGNED_BY,
};

export const COST_CENTERS_COLUMNS = {
  COST_CENTER_NAME: {
    columnName: 'name',
    title: '',
    sortingEnabled: false,
    direction: 'asc',
    align: 'left',
    getCellValue: (row) => row?.name,
  },
  CREATED_AT: {
    columnName: 'createdAt',
    title: '',
    width: 80,
    sortingEnabled: false,
    align: 'right',
    getCellValue: (row) => (row?.creationTime ? moment(row?.creationTime).format('MMM DD YYYY') : null),
  },
  CREATE_SUB_COST_CENTER: {
    columnName: 'createSubCostCenter',
    title: '',
    width: 80,
    sortingEnabled: false,
    align: 'right',
  },
  ADD_LINKED_ACCOUNTS: {
    columnName: 'addLinkedAccounts',
    title: '',
    sortingEnabled: false,
    align: 'right',
  },
  // CHANGE_SCOPE: {
  //   columnName: 'changeScope',
  //   title: '',
  //   width: 50,
  //   sortingEnabled: false,
  //   align: 'right',
  // },
  ACTIONS: {
    columnName: 'actions',
    title: '',
    sortingEnabled: false,
    align: 'right',
  },
};

export const COST_CENTERS_DATA_ACCESS_COLUMNS = {
  COST_CENTER_NAME: { ...COST_CENTERS_COLUMNS.COST_CENTER_NAME },
  ASSIGNED_BY: {
    columnName: 'assignedBy',
    title: '',
    width: 200,
    sortingEnabled: false,
  },
  REMOVE: {
    columnName: 'remove',
    title: '',
    sortingEnabled: false,
    align: 'right',
  },
};

export const COST_CENTER_ACTIONS = {
  // MOVE: { id: 'move', label: 'Move', icon: ICONS.right.name, automationid: 'costCenterMenuActions_Move' },
  DELETE: {
    id: 'delete',
    label: 'Delete',
    icon: ICONS.deleteRegular.name,
    automationid: 'costCenterMenuActions_Delete',
  },
};

export const COST_CENTER_PAYER_ACCOUNTS_COLUMNS = {
  PAYER_ACCOUNT: {
    columnName: 'payerAccount',
    title: 'Payer Account',
    width: 200,
    getCellValue: (row) => row?.payerAccount?.name,
  },
  REMOVE_PAYER_ACCOUNT: {
    columnName: 'removePayerAccount',
    title: '',
    width: 200,
    align: 'right',
    sortingEnabled: false,
  },
};

export const COST_CENTER_LINKED_ACCOUNTS_COLUMNS = {
  LINKED_ACCOUNT: {
    columnName: 'linkedAccountNameAndId',
    title: 'Account Name & ID',
    width: 200,
    sortingEnabled: false,
    direction: 'desc',
    getCellValue: (row) => row?.name,
  },
  REMOVE_LINKED_ACCOUNT: {
    columnName: 'removeLinkedAccount',
    title: '',
    width: 200,
    align: 'right',
    sortingEnabled: false,
  },
};

export const getColumns = (columns, isReseller = undefined) => {
  if (!columns) {
    return null;
  }
  let tmpColumns = Object.values(columns);
  if (tmpColumns.find((c) => c.columnName === 'customer') && isReseller === false) {
    tmpColumns = tmpColumns.filter((c) => c.columnName !== 'customer');
  }
  return Object.values(tmpColumns).map((c) => ({
    name: c.columnName,
    title: c.title,
    getCellValue: c.getCellValue,
  }));
};

export const getSortingColumns = (columns) =>
  Object.values(columns).map((c) => ({ columnName: c.columnName, sortingEnabled: c.sortingEnabled }));

export const getDefaultSorting = (columns) =>
  Object.values(columns)
    .filter((c) => c.direction)
    .map((c) => ({
      columnName: c.columnName,
      direction: c.direction,
    }));

export const calculateColumnWidths = debounce((onSetWidth, columns) => {
  const totalWidth = window.innerWidth - 310;
  const map = columns.map((c) => {
    let width = c.width;
    if (typeof c.width === 'string' && c.width?.toString()?.includes('%')) {
      width = (totalWidth * Number(c.width.replace('%', ''))) / 100;
    }
    return { columnName: c.columnName, width };
  });
  onSetWidth(map);
}, 1000);

export const EDIT_USER_MODAL_FIELDS = {
  USER_NAME: { id: 'username', label: 'User Name', placeholder: 'User Name' },
  FIRST_NAME: { id: 'firstName', label: 'First Name', placeholder: 'First Name' },
  LAST_NAME: { id: 'lastName', label: 'Last Name', placeholder: 'Last Name' },
};

export const GET_USERS_QUERY_PARAMS = {
  SEARCH: { name: 'search', defaultValue: '' },
  PAGINATION_TOKEN: { name: 'paginationToken', defaultValue: null },
  PAGE_SIZE: { name: 'pageSize', defaultValue: DEFAULT_PAGE_SIZE },
  USER_NAME: { name: USERS_COLUMNS.USER_NAME.columnName, defaultValue: '' },
  FIRST_NAME: { name: USERS_COLUMNS.FIRST_NAME.columnName, defaultValue: '' },
  LAST_NAME: { name: USERS_COLUMNS.LAST_NAME.columnName, defaultValue: '' },
  ROLES: { name: USERS_COLUMNS.ROLES.columnName, defaultValue: '' },
  CREATED_AT: { name: USERS_COLUMNS.CREATED_AT.columnName, defaultValue: '' },
  CREATED_AT_FILTER_TYPE: { name: 'createdAtFilterType', defaultValue: '' },
  LAST_LOGIN: { name: USERS_COLUMNS.LAST_LOGIN.columnName, defaultValue: '' },
  LAST_LOGIN_FILTER_TYPE: { name: 'lastLoginFilterType', defaultValue: '' },
  ENABLED: { name: 'enabled', defaultValue: '' },
};

export const GET_ROLES_QUERY_PARAMS = {
  EXCLUDE_BUILT_IN_ROLES: { name: 'excludeBuiltInRoles', defaultValue: 'true' },
  PAGE_SIZE: { name: 'pageSize', defaultValue: DEFAULT_PAGE_SIZE },
  PAGINATION_TOKEN: { name: 'paginationToken', defaultValue: null },
  SEARCH: { name: 'search', defaultValue: '' },
  SHOW_ANODOT: { name: 'showAnodot', defaultValue: null },
};

export const FILTER_OPERATIONS_MAPPING = {
  CONTAINS: { id: FILTER_OPERATIONS.CONTAINS, apiKey: 'CONTAINS' },
  EQUAL: { id: FILTER_OPERATIONS.EQUAL, apiKey: 'EQUALS' },
  NOT_EQUAL: { id: FILTER_OPERATIONS.NOT_EQUAL, apiKey: 'NOT_EQUALS' },
  GREATER_THAN: { id: FILTER_OPERATIONS.GREATER_THAN, apiKey: 'GREATER_THAN' },
  GREATER_THAN_OR_EQUALS: { id: FILTER_OPERATIONS.GREATER_THAN_OR_EQUAL, apiKey: 'GREATER_THAN_OR_EQUALS' },
  LESS_THAN: { id: FILTER_OPERATIONS.LESS_THAN, apiKey: 'LESS_THAN' },
  LESS_THAN_OR_EQUAL: { id: FILTER_OPERATIONS.LESS_THAN, apiKey: 'LESS_THAN_OR_EQUALS' },
};

export const doesUserMatchSearch = (user, search) =>
  (user?.username && user.username.indexOf(search) !== -1) ||
  (user?.firstName && user.firstName.indexOf(search) !== -1) ||
  (user?.lastName && user.lastName.indexOf(search) !== -1);

export const PERMISSION_ACTIONS = {
  LIST: { id: 'LIST', name: 'List' },
  CREATE: { id: 'CREATE', name: 'Create' },
  VIEW: { id: 'VIEW', name: 'View' },
  UPDATE: { id: 'UPDATE', name: 'Edit' },
  IMPERSONATE: { id: 'IMPERSONATE', name: 'Impersonate' },
  EXEC_OFFLINE_TASKS_AS: { id: 'EXEC_OFFLINE_TASKS_AS', name: 'Run As' },
};

export const PERMISSION_TYPES = {
  FULL: { id: 'FULL', name: 'Full' },
  NONE: { id: 'NONE', name: 'None' },
  PARTIAL: { id: 'PARTIAL', name: 'Partial' },
};

export const PERMISSION_ENTITIES = {
  // OrganizationEntityCategory
  ORGANIZATION_MANAGEMENT: { id: 'ORGANIZATION_MANAGEMENT', title: 'Organization Management' },
  ROLES: { id: 'ROLES', title: 'Roles' },
  USERS_MANAGEMENT: { id: 'USERS_MANAGEMENT', title: 'User Management' },
  COST_CENTERS: { id: 'COST_CENTERS', title: 'Cost Centers' },
  RESELLER_CUSTOMERS: { id: 'RESELLER_CUSTOMERS', title: 'Reseller Customers' },
  COST_ALLOCATION: { id: 'COST_ALLOCATION', title: 'Cost Allocation' },
  SYSTEM_CONFIGURATION: { id: 'SYSTEM_CONFIGURATION', title: 'System Configuration' },
  // HierarchicalEntityCategory
  ACCOUNTS: { id: 'ACCOUNTS', title: 'Accounts' },
  DATA_ACCESS: { id: 'DATA_ACCESS', title: 'Data Access' },
  REBILLING_DATA: { id: 'REBILLING_DATA', title: 'Rebilling Data' },
  COMMITMENTS: { id: 'COMMITMENTS', title: 'Commitments' },
  BUDGETS: { id: 'BUDGETS', title: 'Budgets' },
  DASHBOARDS: { id: 'DASHBOARDS', title: 'Dashboards' },
  REPORTS: { id: 'REPORTS', title: 'Reports' },
  ALERTS: { id: 'ALERTS', title: 'Alerts' },
};

export const SHARED_ENTITIES_COLUMNS = {
  ENTITY_NAME: {
    columnName: 'entityName',
    title: 'Entity Name',
    width: 300,
    direction: 'desc',
    sortingEnabled: false,
  },
  SHARED_WITH_ROLE: {
    columnName: 'sharedWithRoles',
    title: 'Shared with Roles',
    width: 300,
    sortingEnabled: false,
  },
};

export const CUSTOMERS_COLUMNS = {
  CUSTOMER_NAME: {
    columnName: 'name',
    title: 'Customer Name',
    width: 250,
    direction: 'asc',
  },
  CUSTOMER_CODE: {
    columnName: 'code',
    title: 'Customer Code',
    width: 'auto',
  },
  CREATED_AT: {
    columnName: 'creationTime',
    title: 'Created at',
    width: 100,
    sortingEnabled: false,
  },
  ACTIONS: { columnName: 'actions', title: '', width: 140, sortingEnabled: false, align: 'right' },
};

export const CUSTOMERS_ACCOUNTS_COLUMNS = {
  ACCOUNT_NAME: {
    columnName: 'name',
    title: 'Account Name',
    width: 250,
    direction: 'asc',
  },
  ACCOUNT_ID: {
    columnName: 'accountId',
    title: 'Account Id',
    width: 'auto',
  },
  CUSTOMERS_COUNT: {
    columnName: 'customersCount',
    title: 'Customers',
    width: 90,
    sortingEnabled: false,
  },
  ACCOUNT_TYPE: {
    columnName: 'accountType',
    title: 'Account Type',
    width: 110,
    sortingEnabled: false,
  },
  AUTO_CUSTOMER: {
    columnName: 'autoCustomer',
    title: 'Auto Customer',
    width: 200,
    sortingEnabled: false,
  },
  AUTO_ASSIGNED: {
    columnName: 'autoAssigned',
    title: 'Auto Assigned',
    width: 110,
    sortingEnabled: false,
  },
  EXCLUDE_RULE: {
    columnName: 'excludeRule',
    title: 'Exclude Rule',
    width: 170,
    sortingEnabled: false,
  },
  ACTIONS: { columnName: 'actions', title: '', width: 40, sortingEnabled: false, align: 'right' },
};

export const CUSTOMER_ACTIONS = {
  EDIT: { id: 'edit', label: 'Edit', icon: ICONS.edit.name, automationid: 'customerMenuActions_Edit' },
  DELETE: { id: 'delete', label: 'Delete', icon: ICONS.deleteRegular.name, automationid: 'customerMenuActions_Delete' },
};

export const CUSTOMER_ADD_REMOVE_LINKED_ACCOUNTS_COLUMNS = {
  LINKED_ACCOUNT: {
    columnName: 'linkedAccountNameAndId',
    title: 'Account Name & ID',
    width: 200,
    sortingEnabled: false,
    direction: 'desc',
    getCellValue: (row) => row?.name,
  },
  ASSIGNED_BY: {
    columnName: 'assignedBy',
    title: '',
    width: 200,
    sortingEnabled: false,
  },
  REMOVE_LINKED_ACCOUNT: {
    columnName: 'removeLinkedAccount',
    title: '',
    width: 'auto',
    align: 'right',
    sortingEnabled: false,
  },
};

export const CUSTOMER_ADD_REMOVE_PAYER_ACCOUNTS_COLUMNS = {
  PAYER_ACCOUNT: {
    columnName: 'payerAccount',
    title: 'Payer Account',
    width: 200,
    getCellValue: (row) => row?.payerAccount?.name,
  },
  REMOVE_LINKED_ACCOUNT: {
    columnName: 'removeLinkedAccount',
    title: '',
    width: 200,
    align: 'right',
    sortingEnabled: false,
  },
};
