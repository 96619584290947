import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from 'shared/components/andtComponents/Spinner';
import { isRouteAllowed, Routes } from 'shared/constants/routes';
import { observer } from 'mobx-react';
import { usePermissionCategories } from 'usage/hooks/react-query/usePermissionCategories';
import useCurrentRoute from '~/shared/hooks/customHooks/useCurrentRoute.jsx';
import TrialEnded from '~/app/containers/TrialEnded/TrialEnded.js';

const AuthenticatedRoute = ({
  component: C,
  props: cProps,
  observeResources = [],
  blockResources = [],
  remountKey,
}) => {
  const { usersStore, appStore } = cProps;
  const navigate = useNavigate();
  const match = useCurrentRoute();
  const location = useLocation();
  const { fetchAccessibleCategories } = usePermissionCategories();
  const { data: permissionCategories, isLoading } = fetchAccessibleCategories();

  useEffect(() => {}, [cProps.usersStore.currUserInitDone, ...observeResources.map((resFunc) => !!resFunc(cProps))]);

  const currPath = location.pathname + location.search;
  const isAuthenticated = cProps?.isSessionStorageAuth();
  const isUserInitialized = cProps.usersStore.currUserInitDone;
  const isBlocked = blockResources.some((resFunc) => !!resFunc(cProps)) || isLoading;
  const isPermissionDenied =
    !isLoading &&
    !isRouteAllowed({
      route: match,
      isKeyCloakManagement: appStore.isKeyCloakManagement,
      permissionCategories,
      deprecatedCurrentDisplayedUserType: usersStore.deprecatedGetCurrentDisplayedUserType,
      isOrgSuperUserAdmin: usersStore.isCurrentUserSuperAdmin,
    }) &&
    location.pathname !== Routes.NOT_ALLOWED;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(`${Routes.LOG_IN}?redirect=${currPath}`);
    }
    if (isPermissionDenied) {
      navigate(Routes.NOT_ALLOWED);
    }
  }, [isAuthenticated, isPermissionDenied, currPath, navigate]);

  if (!isUserInitialized || isBlocked) {
    return <Spinner />;
  }
  if (usersStore.userEndTrial) {
    return <TrialEnded />;
  }
  return <C key={remountKey ? remountKey(cProps) : location.key} {...cProps} isPpApplied={appStore.isPpApplied} />;
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  props: PropTypes.shape({
    usersStore: PropTypes.object.isRequired,
    appStore: PropTypes.object.isRequired,
    isSessionStorageAuth: PropTypes.func.isRequired,
    newTabLoading: PropTypes.bool,
  }).isRequired,
  observeResources: PropTypes.arrayOf(PropTypes.func),
  blockResources: PropTypes.arrayOf(PropTypes.func),
  remountKey: PropTypes.func,
};

export default observer(AuthenticatedRoute);
