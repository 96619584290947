import React, { createContext, useCallback, useMemo, useState } from 'react';
import { K8S_ONBOARDING_STEPS_CONFIG } from './config.ts';
import { K8Cluster } from './types.ts';
import { v4 } from 'uuid';

export const OnboardingContext = createContext({
  addedClusters: [] as K8Cluster[],
  updateCluster: (() => {}) as (updatingCluster: K8Cluster, activeCluster?: K8Cluster | null) => void,
  deleteCluster: (() => {}) as (id: string) => void,
  currentStepIndex: 0,
  isNextDisabled: false,
  onNext: (() => {}) as () => void,
  onBack: (() => {}) as () => void,
});

export const OnboardingContextProvider = ({ children }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [addedClusters, setClusters] = useState<K8Cluster[]>([]);

  const isNextDisabled = useMemo(() => {
    switch (currentStepIndex) {
      case 0:
        return addedClusters.length === 0;
      default:
        return false;
    }
  }, [currentStepIndex, addedClusters.length]);

  const deleteCluster = useCallback((clusterId: string) => {
    setClusters((s) => [...s].filter((c) => c.id !== clusterId));
  }, []);

  const updateCluster = useCallback((updatingCluster: K8Cluster, activeCluster?: K8Cluster | null) => {
    updatingCluster.id = activeCluster?.id || v4();
    setClusters((s) =>
      activeCluster?.id
        ? s.map((cluster) => (cluster.id === activeCluster?.id ? updatingCluster : cluster))
        : [...s, updatingCluster],
    );
  }, []);

  const onNext = useCallback(() => {
    setCurrentStepIndex((s) => Math.min(K8S_ONBOARDING_STEPS_CONFIG.length - 1, s + 1));
  }, []);

  const onBack = useCallback(() => {
    setCurrentStepIndex((s) => Math.max(0, s - 1));
  }, []);

  return (
    <OnboardingContext.Provider
      value={{
        addedClusters,
        updateCluster,
        deleteCluster,
        currentStepIndex,
        isNextDisabled,
        onNext,
        onBack,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
export default OnboardingContextProvider;
