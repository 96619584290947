import { propComparator } from 'shared/utils/sortUtil';
import LabelCoordinator from 'shared/modules/labelCoordinator';

interface CustomerDataItem {
  divisionGroups: { divisionName: string }[];
}

interface NewUMCustomerDataItem {
  name: string;
  id: string;
}

export const getReCustomersForFilter = ({
  isKeyCloakManagement,
  customersData,
}: {
  isKeyCloakManagement: boolean;
  customersData: NewUMCustomerDataItem[] | CustomerDataItem;
}) => {
  const getNewUserManagementReCustomersForFilter = (data: NewUMCustomerDataItem[]) => {
  return data.map(({ name, id }) => ({ value: id, label: name }));
};

const getNonNewUserManagementReCustomersForFilter = (data: CustomerDataItem) => {
  return (data?.divisionGroups || [])
    .map((div) => div.divisionName)
    .map((item) => ({
      value: item,
      label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', item),
    }));
};
  let customers: { value: string; label: string }[];
  if (isKeyCloakManagement) {
    customers = getNewUserManagementReCustomersForFilter(customersData as NewUMCustomerDataItem[]);
  } else {
    customers = getNonNewUserManagementReCustomersForFilter(customersData as CustomerDataItem);
  }
  if (customers.length) {
    customers.sort(propComparator('label'));
  }
  return customers;
};
