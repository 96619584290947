import React from 'react';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as EmptyListImg } from './emptyListImg.svg';
import { ReactComponent as EmptyAddBellImg } from './emptyAddBellImg.svg';
import styles from '../../alerts.module.scss';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper.jsx';
import { Action, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';

export interface EmptyAlertsProps {
  className?: string;
  canAdd?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAdd?(e: any): void;
}

const EmptyAlerts: React.FC<EmptyAlertsProps> = ({ className, canAdd = false, onAdd }) => {
  const description = canAdd ? (
    <>
      <span>You have no alert rules yet.</span>
      <br />
      <span>Would you like to create alert rule?</span>
    </>
  ) : (
    'The option to add an alert is only available if the SP table contains data.'
  );
  return (
    <div className={classNames(className, styles.emptyListContainer)}>
      {canAdd ? <EmptyAddBellImg /> : <EmptyListImg />}
      <div>{description}</div>
      {canAdd && onAdd && (
        <div>
          <ReadOnlyDisplayWrapper isHide={false} category={HierarchicalEntityCategory.Alerts} action={Action.Update}>
            <Button
              isGhost
              automationid="empty-add-button"
              text="Create Alert Rule"
              onClick={onAdd}
              icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
            />
          </ReadOnlyDisplayWrapper>
        </div>
      )}
    </div>
  );
};

export default EmptyAlerts;
