/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import Spinner from 'shared/components/andtComponents/Spinner';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import DraggableList from 'shared/components/tables/DraggableList/index.tsx';
import moment from 'moment';
import { Accordion, AccordionDetails } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import { ReactComponent as K8SIcon } from 'shared/img/icons/k8s.svg';
import Button from 'shared/components/andtComponents/Button';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Chip from 'shared/components/andtComponents/Chip';
import { ReactComponent as PieChartIcon } from 'shared/components/chartComponents/ChartButtonsMenu/assets/charts/pie-chart.svg';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { Routes } from 'shared/constants/routes';
import ButtonMultiDropdown from 'shared/components/andtComponents/ButtonMultiDropdown';
import PageHeader from 'shared/components/PageHeader';
import { usePermissions } from 'app/contexts/PermissionsContext';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import CustomModal from 'shared/components/andtComponents/Modal';
import { useVirtualTags } from 'usage/hooks/react-query/useVirtualTags';
import { BUSINESS_MAPPING_COLUMNS, BUSINESS_MAPPING_COLUMNS_WIDTH } from '../../constants/businessMappingConstants';
import CreateBusinessMappingGroupModal from '../CreateBusinessMappingGroupModal/CreateBusinessMappingGroupModal';
import BusinessMappingGroupActionButton from './BusinessMappingActionButton';
import styles from './BusinessMappingTable.module.scss';
import ExpandedMappingInfo from './ExpandedMappingInfo';
import BusinessMappingPiePreview from '../BusinessMappingPiePreview/BusinessMappingPiePreview';
import ReadOnlyDisplayWrapper from '~/shared/components/ReadOnlyDisplayWrapper.jsx';

const BusinessMappingGroupsTable = ({
  usageStore,
  usersStore,
  kubernetesStore,
  invoiceStore,
  isGlobal = false,
  groups = null,
  viewpoint = null,
  editGroupRank = null,
  columns,
  isGroupsFetching,
  isGroupsLoading,
  setImportFromGlobalModalOpen = null,
  importFromGlobalModalOpen = null,
  setCreateBusinessMappingGroupModalOpen,
  createBusinessMappingGroupModalOpen,
  setDeletingBusinessMappingGroup,
  deletingBusinessMappingGroup = null,
  setCloningBusinessMappingGroup,
  cloningBusinessMappingGroup = null,
  setEditingBusinessMappingGroup,
  editingBusinessMappingGroup = null,
  deleteGroup,
  editGroup,
  addGroup,
  makeGlobalGroup = null,
  hideChangeRankWarning = null,
  importFromGlobalGroup = null,
  isOrderChangeWarningHidden = false,
  globalMappings = [],
}) => {
  const [immediateGroups, setImmediateGroups] = useState(groups);
  const [piePreviewOpen, setPiePreviewOpen] = useState(false);
  const [changeOrderWarning, setChangeOrderWarning] = useState(null);
  const [expandState, setExpandState] = useState({});
  const { fetchVirtualTags } = useVirtualTags();
  const { data: virtualTags } = fetchVirtualTags();
  useEffect(() => {
    setImmediateGroups(groups);
  }, [groups]);
  useEffect(() => {
    // report to segment
    segmentEvent({
      type: 'page',
    });
  }, []);

  const handleOrderChanged = (fIndex, sIndex) => {
    let newOrder = [...groups];
    const fGroup = groups?.[fIndex];
    newOrder.splice(fIndex, 1);
    newOrder.splice(sIndex, 0, fGroup);
    newOrder = newOrder.map((data, index) => ({
      ...data,
      rank: index + 1,
    }));
    setImmediateGroups(newOrder);
    editGroupRank({ data: newOrder, viewpointId: viewpoint?.uuid });
  };
  const { isHasPermission } = usePermissions();
  const updateAllowed = isHasPermission(OrganizationEntityCategory.CostAllocation, Action.Update);

  const renderTableRow = (row, dragProps, isDragging) => {
    const { name, createdBy, rank, creationTime, split, k8s, k8sConfig, global, viewpoints, uuid } = row;
    return (
      <Accordion
        expanded={!!expandState[uuid]}
        onChange={(e, expanded) => setExpandState({ ...expandState, [uuid]: expanded })}
        classes={{ root: `${styles.accordionContainer} ${isDragging ? styles.dragging : ''}` }}
      >
        <AccordionSummary
          {...(isGlobal ? {} : dragProps)}
          expandIcon={<GenerateIcon iconName={ICONS.chevronDown.name} className={styles.arrowIcon} />}
          className={styles.tableRow}
        >
          <span style={{ width: BUSINESS_MAPPING_COLUMNS_WIDTH.NAME }} data-bold="true">
            {!isGlobal && <GenerateIcon iconName={ICONS.gripDots.name} className={styles.dragIcon} />}
            {name}
          </span>
          {!isGlobal && (
            <span style={{ width: BUSINESS_MAPPING_COLUMNS_WIDTH.RANK }} data-bold="true">
              {rank}
            </span>
          )}
          <span style={{ width: BUSINESS_MAPPING_COLUMNS_WIDTH.CREATED_BY }}>{createdBy}</span>
          <span style={{ width: BUSINESS_MAPPING_COLUMNS_WIDTH.CREATED_DATE }}>
            {moment(creationTime).format('DD.MM.YY')}
          </span>
          {isGlobal && (
            <span style={{ width: BUSINESS_MAPPING_COLUMNS_WIDTH.VIEWPOINTS }}>
              {viewpoints.map((v) => v.name).join(', ')}
            </span>
          )}
          {!isGlobal && (
            <span style={{ width: BUSINESS_MAPPING_COLUMNS_WIDTH.TYPE }}>
              {split && !isGlobal && <Chip label="split cost" color="blue" size="big" />}
            </span>
          )}
          <div className="d-flex ms-auto me-3 align-items-center">
            {k8sConfig && k8sConfig.podsView ? <Chip label="pods" color="blue" size="big" /> : null}
            {k8s && <K8SIcon className="me-2" />}
            {global && <GenerateIcon iconName={ICONS.globe.name} className="me-2" />}
            <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.CostAllocation} action={Action.Update}>
              <BusinessMappingGroupActionButton
                group={row}
                disabled={!updateAllowed}
                handleMakeGlobal={
                  isGlobal || row.global || row.split
                    ? undefined
                    : async (...params) => {
                        await makeGlobalGroup(...params);
                        toast.success(`Mapping ${row.name} was converted to global.`);
                      }
                }
                handleDelete={setDeletingBusinessMappingGroup}
                handleClone={isGlobal ? undefined : setCloningBusinessMappingGroup}
                handleEdit={row.global && !isGlobal ? undefined : setEditingBusinessMappingGroup}
              />
            </ReadOnlyDisplayWrapper>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ExpandedMappingInfo groups={groups} row={row} virtualTags={virtualTags} usersStore={usersStore} />
        </AccordionDetails>
      </Accordion>
    );
  };

  const deleteModalMessage = (() => {
    if (!deletingBusinessMappingGroup) {
      return {};
    }
    const { name, uuid } = deletingBusinessMappingGroup;
    const splitRelatedGroups = groups?.filter((g) => g.split && g.splitOptions.mappings.includes(uuid));
    if (isEmptyArray(splitRelatedGroups)) {
      return {
        message: `Be advised, You are about to delete ${name} mapping.`,
        disabled: false,
      };
    }
    return {
      message: () => (
        <p>
          <span className="font-weight-bold">{splitRelatedGroups.map((m) => m.name).join(', ')}</span>{' '}
          {splitRelatedGroups.length === 1 ? 'is' : 'are'} connected to this mapping, remove it from split cost mappings
          to be able to delete
        </p>
      ),
      disabled: true,
    };
  })();

  const someExpanded = Object.values(expandState).some(Boolean);

  return (
    <div className="mb-5">
      <Link to={`${Routes.BUSINESS_MAPPING}?isK8S=${!!(viewpoint || {}).k8s}`} className={styles.backLink}>
        <GenerateIcon iconName={ICONS.chevronDown.name} /> Back to Business Mapping Viewpoints
      </Link>
      {isGlobal ? (
        <PageHeader title="Global Mappings" />
      ) : (
        viewpoint &&
        viewpoint.name && (
          <PageHeader
            title={
              <>
                {viewpoint.name}
                {viewpoint.k8s && (
                  <Tooltip title="This Viewpoint is based on K8s Cost & Usage">
                    <span className="ms-2">
                      <K8SIcon />
                    </span>
                  </Tooltip>
                )}
              </>
            }
          />
        )
      )}
      <div className={styles.subtitle}>
        <span>
          {isGlobal
            ? 'Create mappings that can be applied to all current and future Viewpoints'
            : 'Create business mapping rules for Cost Allocation visualizations'}
        </span>
        <div className={styles.menu}>
          {!isGlobal && (
            <div className={styles.previewButtonContainer}>
              <Button
                text=""
                isGhost
                onClick={() => setPiePreviewOpen(true)}
                icon={PieChartIcon}
                automationid="displayBMPiePreview"
              />
              {piePreviewOpen && (
                <BusinessMappingPiePreview
                  onClose={() => setPiePreviewOpen(false)}
                  invoiceStore={invoiceStore}
                  kubernetesStore={kubernetesStore}
                  viewpoint={viewpoint}
                  splitWarning={(groups || []).some((g) => g.split)}
                />
              )}
            </div>
          )}
          <ReadOnlyDisplayWrapper
            isHide={false}
            category={OrganizationEntityCategory.CostAllocation}
            action={Action.Update}
          >
            {isGlobal ? (
              <Button
                text="New Global Mapping"
                automationid="newGlobalMapping"
                onClick={() => setCreateBusinessMappingGroupModalOpen(true)}
                disabled={!updateAllowed}
                icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
              />
            ) : (
              <ButtonMultiDropdown
                automationid="newMapping"
                dropdownProps={{
                  text: 'New Mapping',
                  isSecondary: false,
                  icon: () => <GenerateIcon iconName={ICONS.plus.name} />,
                  overrideMenuStyles: { minWidth: 170 },
                }}
                items={[
                  {
                    id: 'createNewMapping',
                    simpleOption: true,
                    simpleOptionContent: (
                      <li onClick={() => setCreateBusinessMappingGroupModalOpen(true)}>
                        <span>Create New Mapping</span>
                      </li>
                    ),
                  },
                  {
                    id: 'importFromGlobalMapping',
                    simpleOption: true,
                    simpleOptionContent: (
                      <li onClick={() => setImportFromGlobalModalOpen(true)}>
                        <span>Import from Global Mappings</span>
                      </li>
                    ),
                  },
                ]}
              />
            )}
          </ReadOnlyDisplayWrapper>
        </div>
      </div>
      <div>
        {(isGroupsLoading || isGroupsFetching) && <Spinner />}
        <div className={styles.tableHead}>
          {Object.keys(columns).map((key) => (
            <span style={{ width: BUSINESS_MAPPING_COLUMNS_WIDTH[key] }} key={key}>
              {columns[key]}
              {columns[key] === BUSINESS_MAPPING_COLUMNS.RANK && (
                <Tooltip
                  title="Drag and drop rules to adjust ranking.
                     Any overlapping cost would be attributed to the higher ranked rule."
                >
                  <span className={styles.infoIcon}>
                    <InfoIcon />
                  </span>
                </Tooltip>
              )}
            </span>
          ))}
          <div className={styles.expandAll}>
            <Button
              text={someExpanded ? 'Collapse All' : 'Expand All'}
              onClick={() => {
                if (someExpanded) {
                  setExpandState({});
                  return;
                }
                setExpandState(
                  (groups || []).reduce(
                    (acc, item) => ({
                      ...acc,
                      [item.uuid]: true,
                    }),
                    {},
                  ),
                );
              }}
              isTextButton
              isGhost
              icon={() => (
                <GenerateIcon iconName={someExpanded ? ICONS.arrowsToLine.name : ICONS.arrowsFromLine.name} />
              )}
            />
          </div>
        </div>
        <DraggableList
          noWidth
          items={immediateGroups || []}
          onChangeOrder={(fIndex, sIndex) => {
            if (!isOrderChangeWarningHidden) {
              setChangeOrderWarning({ fIndex, sIndex });
            } else {
              handleOrderChanged(fIndex, sIndex);
            }
          }}
          renderItem={renderTableRow}
        />
      </div>
      {(createBusinessMappingGroupModalOpen ||
        cloningBusinessMappingGroup ||
        editingBusinessMappingGroup ||
        importFromGlobalModalOpen) && (
        <CreateBusinessMappingGroupModal
          isOpen
          globalMappings={globalMappings
            .filter((gm) => !!gm.k8s === !!(viewpoint ? viewpoint.k8s : false))
            .filter(
              (gm) =>
                !viewpoint ||
                !viewpoint.k8s ||
                !!(gm.k8sConfig || {}).podsView === !!(viewpoint.k8sConfig || {}).podsView,
            )}
          isImportGlobalMode={importFromGlobalModalOpen}
          isGlobalMode={isGlobal}
          isCloneMode={cloningBusinessMappingGroup}
          isEditMode={editingBusinessMappingGroup}
          isK8S={viewpoint ? viewpoint.k8s : false}
          isK8SPods={viewpoint && viewpoint.k8sConfig ? viewpoint.k8sConfig.podsView : false}
          onSave={async (data, isImportMode) => {
            const prepData = {
              ...data,
              viewpointId: data.viewpointId || (viewpoint ? viewpoint.uuid : null),
            };
            if (prepData.group) {
              prepData.group.k8s = viewpoint ? viewpoint.k8s : data.group.k8s;
            }
            if (editingBusinessMappingGroup) {
              await editGroup(prepData);
              toast.success(`Mapping ${prepData.group.name} was saved.`);
              return;
            }
            if (importFromGlobalModalOpen && isImportMode) {
              await importFromGlobalGroup(prepData);
              toast.success(`Global mapping ${prepData.group.name} was added to viewpoint.`);
              return;
            }
            await addGroup(prepData);
            toast.success(`Mapping ${prepData.group.name} was created.`);
          }}
          usersStore={usersStore}
          kubernetesStore={kubernetesStore}
          groups={groups}
          takenNames={(groups || [])
            .filter((v) => !editingBusinessMappingGroup || v.uuid !== editingBusinessMappingGroup.uuid)
            .map((v) => v.name)}
          group={cloningBusinessMappingGroup || editingBusinessMappingGroup || null}
          invoiceStore={invoiceStore}
          closeModal={() => {
            setCreateBusinessMappingGroupModalOpen(null);
            setEditingBusinessMappingGroup(null);
            setCloningBusinessMappingGroup(null);
            if (setImportFromGlobalModalOpen) {
              setImportFromGlobalModalOpen(null);
            }
          }}
        />
      )}
      {deletingBusinessMappingGroup && (
        <DeleteWarningModal
          isOpen
          handleDelete={async (action) => {
            if (action !== 'delete') {
              setDeletingBusinessMappingGroup(null);
              return;
            }
            await deleteGroup({
              groupId: deletingBusinessMappingGroup.uuid,
              viewpointId: viewpoint ? viewpoint.uuid : null,
            });
            toast.success(`Mapping ${deletingBusinessMappingGroup.name} was deleted.`);
          }}
          modalTitle="Delete Mapping"
          buttonDisabled={deleteModalMessage.disabled}
          warningMessage={deleteModalMessage.message}
        />
      )}
      {changeOrderWarning && (
        <CustomModal
          open
          title={`Are you sure you want to change the ranking of ${groups?.[changeOrderWarning.fIndex]?.name}?`}
          saveTitle="Continue"
          secondCancelTitle="Don’t ask me again"
          onClose={() => {
            setChangeOrderWarning(null);
          }}
          onSave={() => {
            setChangeOrderWarning(null);
            handleOrderChanged(changeOrderWarning.fIndex, changeOrderWarning.sIndex);
          }}
          onSecondCancelClick={() => {
            setChangeOrderWarning(null);
            hideChangeRankWarning();
          }}
        >
          {null}
        </CustomModal>
      )}
    </div>
  );
};

BusinessMappingGroupsTable.propTypes = {
  usageStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  kubernetesStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  isGlobal: PropTypes.bool,
  groups: PropTypes.array,
  viewpoint: PropTypes.object,
  editGroupRank: PropTypes.func,
  columns: PropTypes.object.isRequired,
  isGroupsFetching: PropTypes.bool.isRequired,
  isGroupsLoading: PropTypes.bool.isRequired,
  setImportFromGlobalModalOpen: PropTypes.func,
  importFromGlobalModalOpen: PropTypes.bool,
  setCreateBusinessMappingGroupModalOpen: PropTypes.func.isRequired,
  createBusinessMappingGroupModalOpen: PropTypes.bool.isRequired,
  setDeletingBusinessMappingGroup: PropTypes.func.isRequired,
  deletingBusinessMappingGroup: PropTypes.bool,
  setCloningBusinessMappingGroup: PropTypes.func.isRequired,
  cloningBusinessMappingGroup: PropTypes.bool,
  setEditingBusinessMappingGroup: PropTypes.func.isRequired,
  editingBusinessMappingGroup: PropTypes.bool,
  deleteGroup: PropTypes.func.isRequired,
  editGroup: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
  makeGlobalGroup: PropTypes.func,
  hideChangeRankWarning: PropTypes.func,
  importFromGlobalGroup: PropTypes.func,
  isOrderChangeWarningHidden: PropTypes.bool,
  globalMappings: PropTypes.array,
};

export default observer(BusinessMappingGroupsTable);
