import React, { useCallback, useState } from 'react';
import { SelectLinkedAccountField } from './SelectLinkAccountField.tsx';
import commonStyles from 'app/containers/Onboarding/AwsOnboarding/pages/Pages.module.scss';
import { AddClusterFields, addClusterLabels } from '../config.ts';
import SwitchButton from 'shared/components/andtComponents/Switch';
import classNames from 'classnames';
import Input from 'shared/components/andtComponents/Input';
import styles from '../K8sOnboarding.module.scss';
import { FieldError, useFormContext } from 'react-hook-form';
import HttpRequestsField from './HttpRequestsField.tsx';
import RadioButtonsList from 'shared/components/andtComponents/RadioButtonList';
import Button from 'shared/components/andtComponents/Button.tsx';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

const errorSign = <span style={{ color: 'rgb(209, 15, 55)' }}> !</span>;

const getErrorMessage = (error: FieldError | undefined) =>
  typeof error?.message === 'string' ? error?.message : String(error || ' ');

const useGetFieldsList = () => {
  const [openMap, setOpenMap] = useState({});
  const {
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const onInputChange = useCallback(
    (fieldName: string) => (e) => {
      setValue(fieldName, e.target.value, { shouldValidate: true });
      clearErrors(fieldName);
    },
    [setValue, clearErrors],
  );

  const onSwitch = useCallback(
    (key: AddClusterFields, value?: boolean) => () =>
      setOpenMap((d) => ({
        ...d,
        [key]: value === undefined ? !d[key] : value,
      })),
    [],
  );

  const onShowServer = useCallback(
    (value) => {
      onSwitch(AddClusterFields.haveServer_block, value)();
      setValue(AddClusterFields.haveServer_block, value, { shouldValidate: true });
      if (!value) clearErrors(AddClusterFields.haveServer_block);
    },
    [setValue, clearErrors, onSwitch],
  );

  const defaultFieldsList = [
    {
      render: () => (
        <SelectLinkedAccountField
          onChange={(value) => {
            setValue(AddClusterFields.linkedAccountId, value, { shouldValidate: true });
          }}
          value={getValues(AddClusterFields.linkedAccountId)}
          isInvalid={!!errors[AddClusterFields.linkedAccountId]}
        />
      ),
      key: AddClusterFields.linkedAccountId,
    },
    {
      render: () => (
        <InputWithLabel
          error={errors[AddClusterFields.clusterName]}
          label={addClusterLabels[AddClusterFields.clusterName]?.label}
          fieldName={AddClusterFields.clusterName}
          onChange={onInputChange(AddClusterFields.clusterName)}
          value={getValues(AddClusterFields.clusterName)}
          name={AddClusterFields.clusterName}
          required
        />
      ),
      key: AddClusterFields.clusterName,
    },
    {
      render: () => (
        <InputWithLabel
          error={errors[AddClusterFields.agentNamespace]}
          label={addClusterLabels[AddClusterFields.agentNamespace]?.label}
          fieldName={AddClusterFields.agentNamespace}
          onChange={onInputChange(AddClusterFields.agentNamespace)}
          value={getValues(AddClusterFields.agentNamespace)}
          isInvalid={!!errors[AddClusterFields.agentNamespace]}
          invalidComponent={getErrorMessage(errors[AddClusterFields.agentNamespace] as FieldError)}
        />
      ),
      key: AddClusterFields.agentNamespace,
    },
    {
      render: () => (
        <div className={commonStyles.inputField}>
          <label htmlFor="onboarding-import-type">
            <span>I already have Prometheus server installed for this cluster</span>
          </label>
          <div className={commonStyles.checkboxField}>
            <RadioButtonsList
              onChange={onShowServer}
              options={[
                {
                  value: true,
                  key: 1,
                  label: 'Yes',
                  primary: true,
                },
                {
                  value: false,
                  key: 2,
                  label: 'No',
                  primary: true,
                },
              ]}
              value={getValues(AddClusterFields.haveServer_block)}
              direction="row"
            />
          </div>
        </div>
      ),
      key: AddClusterFields.haveServer_block,
    },
  ];

  const showServerFields = openMap[AddClusterFields.haveServer_block];

  const existingServerFields = !showServerFields
    ? []
    : [
        {
          render: () => (
            <InputWithLabel
              error={errors[AddClusterFields.prometheusUrl]}
              label={addClusterLabels[AddClusterFields.prometheusUrl]?.label}
              fieldName={AddClusterFields.prometheusUrl}
              onChange={onInputChange(AddClusterFields.prometheusUrl)}
              value={getValues(AddClusterFields.prometheusUrl)}
            />
          ),
          key: AddClusterFields.prometheusUrl,
        },
        {
          render: () => {
            const isError =
              errors[AddClusterFields.prometheusConnectingCreds_username] ||
              errors[AddClusterFields.prometheusConnectingCreds_password];
            return (
              <SwitchButton
                label={
                  <span>Connecting to Prometheus requires user name and password{isError ? errorSign : null}</span>
                }
                isChecked={!!openMap[AddClusterFields.prometheusConnectingCreds_block]}
                onChange={onSwitch(AddClusterFields.prometheusConnectingCreds_block)}
              />
            );
          },
          key: AddClusterFields.prometheusConnectingCreds_block,
        },
        {
          render: () => {
            return (
              <div className={commonStyles.inputsNestedContainer}>
                <InputWithLabel
                  label={'User name'}
                  error={errors[AddClusterFields.prometheusConnectingCreds_username]}
                  onChange={onInputChange(AddClusterFields.prometheusConnectingCreds_username)}
                  value={getValues(AddClusterFields.prometheusConnectingCreds_username)}
                  fieldName={AddClusterFields.prometheusConnectingCreds_username}
                />
                <PasswordInput
                  className={'mt-2'}
                  label={'Password'}
                  error={errors[AddClusterFields.prometheusConnectingCreds_password]}
                  onChange={onInputChange(AddClusterFields.prometheusConnectingCreds_password)}
                  value={getValues(AddClusterFields.prometheusConnectingCreds_password)}
                  fieldName={AddClusterFields.prometheusConnectingCreds_password}
                  invalidComponent={getErrorMessage(
                    errors[AddClusterFields.prometheusConnectingCreds_password] as FieldError,
                  )}
                  isInvalid={Boolean(errors[AddClusterFields.prometheusConnectingCreds_password])}
                />
              </div>
            );
          },
          key: AddClusterFields.prometheusConnectingCreds_username,
          disabled: !openMap[AddClusterFields.prometheusConnectingCreds_block],
        },
        {
          render: () => (
            <SwitchButton
              label={'Special HTTP request headers are required*'}
              isChecked={!!openMap[AddClusterFields.httpHeaders_block]}
              onChange={onSwitch(AddClusterFields.httpHeaders_block)}
            />
          ),
          key: AddClusterFields.httpHeaders_block,
        },
        {
          render: () => (
            <HttpRequestsField
              setHeaders={(newHeaders) => {
                setValue(AddClusterFields.httpHeaders, newHeaders, { shouldValidate: true });
                clearErrors(AddClusterFields.httpHeaders);
              }}
              headers={getValues(AddClusterFields.httpHeaders)}
              error={getErrorMessage(errors[AddClusterFields.httpHeaders] as FieldError)}
            />
          ),
          key: AddClusterFields.httpHeaders,
          disabled: !openMap[AddClusterFields.httpHeaders_block],
        },
        {
          render: () => (
            <SwitchButton
              label={'Using non-default name for the kube-state-metrics job '}
              isChecked={!!openMap[AddClusterFields.kubeStateMetricsJobName_block]}
              onChange={onSwitch(AddClusterFields.kubeStateMetricsJobName_block)}
              style={openMap[AddClusterFields.kubeStateMetricsJobName_block] ? { marginBottom: '-25px' } : {}}
            />
          ),
          key: AddClusterFields.kubeStateMetricsJobName_block,
        },
        {
          render: () => (
            <InputWithLabel
              error={errors[AddClusterFields.kubeStateMetricsJobName]}
              label={''}
              onChange={onInputChange(AddClusterFields.kubeStateMetricsJobName)}
              value={getValues(AddClusterFields.kubeStateMetricsJobName)}
              name={AddClusterFields.kubeStateMetricsJobName}
            />
          ),
          key: AddClusterFields.kubeStateMetricsJobName,
          disabled: !openMap[AddClusterFields.kubeStateMetricsJobName_block],
        },
        {
          render: () => (
            <SwitchButton
              label={'Using non-default name for the kubelet job'}
              isChecked={!!openMap[AddClusterFields.kubeletJobName_block]}
              onChange={onSwitch(AddClusterFields.kubeletJobName_block)}
              style={openMap[AddClusterFields.kubeletJobName_block] ? { marginBottom: '-25px' } : {}}
            />
          ),
          key: AddClusterFields.kubeletJobName_block,
        },
        {
          render: () => (
            <InputWithLabel
              error={errors[AddClusterFields.kubeletJobName]}
              label={''}
              onChange={onInputChange(AddClusterFields.kubeletJobName)}
              value={getValues(AddClusterFields.kubeletJobName)}
              name={AddClusterFields.kubeletJobName}
            />
          ),
          key: AddClusterFields.kubeletJobName,
          disabled: !openMap[AddClusterFields.kubeletJobName_block],
        },
        {
          render: () => (
            <SwitchButton
              label={'Prometheus stores metrics from other clusters'}
              isChecked={!!openMap[AddClusterFields.otherMetrics_block]}
              onChange={onSwitch(AddClusterFields.otherMetrics_block)}
            />
          ),
          key: AddClusterFields.otherMetrics_block,
        },
        {
          render: () => (
            <div className={commonStyles.inputsNestedContainer}>
              <div>Label used to identify metrics from this cluster:</div>
              <div className={classNames('d-flex mt-2')}>
                <div className={commonStyles.inputBox}>
                  <label htmlFor={AddClusterFields.otherMetrics_key}>Label key</label>
                  <Input
                    className={styles.noMinWidth}
                    onChange={onInputChange(AddClusterFields.otherMetrics_key)}
                    value={getValues(AddClusterFields.otherMetrics_key)}
                    name={AddClusterFields.otherMetrics_key}
                    invalidComponent={getErrorMessage(errors[AddClusterFields.otherMetrics_key] as FieldError)}
                    isInvalid={Boolean(errors[AddClusterFields.otherMetrics_key])}
                  />
                </div>
                <div className={classNames(commonStyles.inputBox, 'ms-3')}>
                  <label htmlFor={AddClusterFields.otherMetrics_value}>Label value</label>
                  <Input
                    className={styles.noMinWidth}
                    onChange={onInputChange(AddClusterFields.otherMetrics_value)}
                    value={getValues(AddClusterFields.otherMetrics_value)}
                    name={AddClusterFields.otherMetrics_value}
                    invalidComponent={getErrorMessage(errors[AddClusterFields.otherMetrics_value] as FieldError)}
                    isInvalid={Boolean(errors[AddClusterFields.otherMetrics_value])}
                  />
                </div>
              </div>
            </div>
          ),
          key: AddClusterFields.otherMetrics_value,
          disabled: !openMap[AddClusterFields.otherMetrics_block],
        },
      ];

  return { fieldsList: [...defaultFieldsList, ...existingServerFields] };
};

export default useGetFieldsList;

function InputWithLabel({ label, style = {}, fieldName = '', error, ...inputProps }) {
  const invalidComponent = <span>{getErrorMessage(error)}</span>;
  const isInvalid = Boolean(error) && error?.type !== 'GeneralError';
  return (
    <div className={classNames(commonStyles.inputField, isInvalid && 'mb-2')} style={style}>
      <label htmlFor={fieldName}>{label}</label>
      <div className={commonStyles.inputBox}>
        <Input name={fieldName} invalidComponent={invalidComponent} isInvalid={isInvalid} {...inputProps} />
      </div>
    </div>
  );
}

function PasswordInput({ className, label, fieldName = '', error, ...inputProps }) {
  const [isText, setIsText] = React.useState(false);
  const eyeIcon = () => <GenerateIcon iconName={ICONS[isText ? 'eye' : 'eyeSlash'].name} />;
  return (
    <div className={classNames(className, commonStyles.inputField, !!error && 'mb-2')}>
      <label htmlFor={fieldName}>{label}</label>
      <div className={commonStyles.inputBox}>
        <Input {...inputProps} type={isText ? 'text' : 'password'} />
        <Button
          overrideStyles={{ position: 'absolute', right: '0', top: 'calc(50% - 18px)' }}
          isSecondary
          isTextButton
          icon={eyeIcon}
          onClick={() => setIsText(!isText)}
        />
      </div>
    </div>
  );
}
