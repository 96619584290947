import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Divider, IconButton } from '@mui/material';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { useCUEEvents } from 'users/hooks/react-query/useCUEEvents';
import Button from 'shared/components/andtComponents/Button';
import EventCreateModal from './EventCreateModal';
import DeleteWarningModal from '../DeleteWarningModal';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { Action, CategoryAction, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';

const EventsSideBar = React.forwardRef(({ startDate, endDate, granLevel }, ref) => {
  const { fetchCUEEvents, addCUEEvent, deleteCUEEvent, updateCUEEvent } = useCUEEvents();
  const { data: events = [], isLoading } = fetchCUEEvents(startDate, endDate, granLevel, true);
  const { mutateAsync: handleCreateEvent, isLoading: createLoading } = addCUEEvent();
  const { mutateAsync: handleDeleteEvent, isLoading: deleteLoading } = deleteCUEEvent();
  const { mutateAsync: handleEditEvent, isLoading: editLoading } = updateCUEEvent();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deletingEvent, setDeletingEvent] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const { getCurrencyNumber } = useUserSettingsContext();
  const isEventsLoading = isLoading || createLoading || deleteLoading || editLoading;
  React.useImperativeHandle(ref, () => ({
    setExpanded: (id) => setExpanded(id),
  }));
  return (
    <div className="events-sidebar">
      <EventCreateModal
        onClose={() => setCreateModalOpen(false)}
        handleCreateEvent={handleCreateEvent}
        isOpen={createModalOpen}
        isLoading={isEventsLoading}
      />
      {editingEvent && (
        <EventCreateModal
          onClose={() => setEditingEvent(false)}
          handleCreateEvent={handleEditEvent}
          isOpen
          editItem={editingEvent}
          isLoading={isEventsLoading}
        />
      )}
      <DeleteWarningModal
        isOpen={!!deletingEvent}
        buttonDisabled={isEventsLoading}
        handleDelete={async (action) => {
          if (action === 'delete') {
            await handleDeleteEvent({ id: deletingEvent.uuid });
          }
          setDeletingEvent(null);
        }}
        modalTitle="Delete Event"
        warningMessage={`Be advised, You are about to delete "${(deletingEvent || {}).title || ''}" Event`}
      />
      <div className="events-sidebar__header">
        <h5>Events:</h5>
      </div>
      <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.CostAllocation} action={CategoryAction.Create}>
        <Button
          overrideStyles={{
            display: 'flex',
            padding: '5px 10px',
            alignItems: 'center',
            borderBottom: 'solid 0.5px #d8d8d8',
            width: '100%',
            fontSize: '0.9rem',
            transition: 'background-color 300ms, color 300ms',
            userSelect: 'none',
            borderRadius: 0,
          }}
          text="Create Event"
          isTextButton
          icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
          iconSize={25}
          onClick={() => setCreateModalOpen(true)}
          automationid="create-event-button"
        />
      </ReadOnlyDisplayWrapper>
      <div className="events-sidebar__container">
        {events.map((event) => (
          <Accordion
            key={event.uuid}
            className="events-sidebar__item"
            square
            expanded={expanded === event.uuid}
            onChange={() => setExpanded(expanded === event.uuid ? false : event.uuid)}
          >
            <AccordionSummary expandIcon={<GenerateIcon iconName={ICONS.chevronDown.name} />}>
              <span className="events-sidebar__item--title">{event.title}</span>
              <span className="events-sidebar__item--date">{event.date}</span>
              {expanded === event.uuid && (
                <>
                  <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.CostAllocation} action={Action.Update}>
                    <IconButton
                      className="events-sidebar__item--edit"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingEvent(event);
                      }}
                      size="large"
                    >
                      <GenerateIcon iconName={ICONS.pencil.name} size={'2xs'} />
                    </IconButton>
                  </ReadOnlyDisplayWrapper>
                  <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.CostAllocation} action={Action.Update}>
                    <IconButton
                      className="events-sidebar__item--delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeletingEvent(event);
                      }}
                      size="large"
                    >
                      <GenerateIcon iconName={ICONS.delete.name} size={'2xs'} />
                    </IconButton>
                  </ReadOnlyDisplayWrapper>
                </>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Divider variant="middle" />
              <span className="events-sidebar__item--description">{event.description}</span>
              {event.estimation ? (
                <span className="mt-2">
                  Impact Estimation: <b>{getCurrencyNumber(event.estimation)}</b>
                </span>
              ) : null}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
});

EventsSideBar.propTypes = {
  granLevel: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
};

export default EventsSideBar;
